// import {IColumn} from '@fluentui/react';

import {IColumn} from '@fluentui/react';
import { ColumnType } from '../IEnhancedDetailsList';
import { EnhancedColumn } from './EnhancedColumn';
import { SplitCabinetButton } from '../../SplitCabinetButton';

export class OpenPDCCabinetColumn extends EnhancedColumn {
  public static type = ColumnType.Open;
  public static canFilter = false;
  public static canSort = false;
  public static isResizable = false;
  public static minWidth = 122;
  public static maxWidth = 122;
  public static renderer(value: string, column: IColumn, item?: any): JSX.Element {
    const siteAbsoluteUrlRegex = value && value.match(/.+\/ims\/[^/]+\//);
    const siteAbsoluteUrl = siteAbsoluteUrlRegex ? siteAbsoluteUrlRegex[0] : undefined;
    const viewInPDC = {
        key: 'ViewinPDC',
        text: 'View in PDC',
        iconProps: {
          iconName: 'EntitlementPolicy'
        },
        href: `${siteAbsoluteUrl}Forms/AllItems.aspx?RootFolder=%2Fsites%2Fims%2FCabinet%20Typicals%2F${item.typicalName.replace(' ', '%20')}`,
        target: '_blank'
      }
    
    const extraMenuProps = [viewInPDC];
        return value ? (<SplitCabinetButton previewUrl={item.previewUrl} url={value} id={item.id} cabinetTypicalName={item.typicalName}  extraMenuProps={extraMenuProps} />)
        : <></>;
  }
}
