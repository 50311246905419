import {Links, UserDto} from '../services/generated';
import {CabinetTypicalDto} from '../services/generated/models/CabinetTypicalDto';
import {DesignForExAreasType} from '../services/generated/models/DesignForExAreasType';
import {YesNoNaType} from '../services/generated/models/YesNoNaType';
import {NativeToolType} from '../services/generated/models/NativeToolType';

export class CabinetTypical implements CabinetTypicalDto {
  public id: number;
  public readonly link: Array<Links> | null;
  public created: string | null;
  public createdBy: UserDto | null;
  public deleted: boolean;
  public modified: string | null;
  public modifiedBy: UserDto | null;
  public version: number;
  public typicalName: string | null;
  public nativeToolType: NativeToolType;
  public versionOfNativeTool: string | null;
  public storageOfNativeFile: string | null;
  public componentCatalogName: string | null;
  public designForExAreasType: DesignForExAreasType;
  public halogenFree: YesNoNaType;
  public redundancyType: YesNoNaType;
  public description: string | null;
  public type: string | null;
  public plinth: string | null;
  public height: string | null;
  public width: string | null;
  public depth: string | null;
  public ip: string | null;
  public cabinetAccess: string | null;
  public ioCount: number | null;
  public numberOfControllers: number | null;
  public equipmentOnDoor: YesNoNaType;
  public cableEntry: string | null;
  public powerSupply: string | null;
  public powerSupplyProducer: string | null;
  public cabinetCatalogNo: string | null;
  public manufacturedBy: string | null;
  public cabinetFrame: string | null;
  public controlSystem: string | null;
  public layout: YesNoNaType;
  public wiring: YesNoNaType;
  public fuseList: YesNoNaType;
  public bom: YesNoNaType;
  public previewUrl: string | null;
  public url: string | null;

  public desingForExAreasString: string = '';
  public redundancyString: string = '';
  public nativeToolString: string = '';
  public halogenFreeString: string = '';
  public layoutString: string = '';
  public wiringString: string = '';
  public fuseListString: string = '';
  public bomString: string = '';
  public equipmentOnDoorString: string = '';


  constructor(cabinetTypical: CabinetTypicalDto) {
    this.id = cabinetTypical.id;
    this.link = cabinetTypical.link;
    this.created = cabinetTypical.created;
    this.createdBy = cabinetTypical.createdBy;
    this.deleted = cabinetTypical.deleted;
    this.modified = cabinetTypical.modified;
    this.modifiedBy = cabinetTypical.modifiedBy;
    this.version = cabinetTypical.version;
    this.typicalName = cabinetTypical.typicalName;
    this.nativeToolType = cabinetTypical.nativeToolType;
    this.versionOfNativeTool = cabinetTypical.versionOfNativeTool;
    this.storageOfNativeFile = cabinetTypical.storageOfNativeFile;
    this.componentCatalogName = cabinetTypical.componentCatalogName;
    this.designForExAreasType = cabinetTypical.designForExAreasType;
    this.halogenFree = cabinetTypical.halogenFree;
    this.redundancyType = cabinetTypical.redundancyType;
    this.description = cabinetTypical.description;
    this.type = cabinetTypical.type;
    this.plinth = cabinetTypical.plinth;
    this.height = cabinetTypical.height;
    this.width = cabinetTypical.width;
    this.depth = cabinetTypical.depth;
    this.ip = cabinetTypical.ip;
    this.cabinetAccess = cabinetTypical.cabinetAccess;
    this.ioCount = cabinetTypical.ioCount;
    this.numberOfControllers = cabinetTypical.numberOfControllers;
    this.equipmentOnDoor = cabinetTypical.equipmentOnDoor;
    this.cableEntry = cabinetTypical.cableEntry;
    this.powerSupply = cabinetTypical.powerSupply;
    this.powerSupplyProducer = cabinetTypical.powerSupplyProducer;
    this.cabinetCatalogNo = cabinetTypical.cabinetCatalogNo;
    this.manufacturedBy = cabinetTypical.manufacturedBy;
    this.cabinetFrame = cabinetTypical.cabinetFrame;
    this.controlSystem = cabinetTypical.controlSystem;
    this.layout = cabinetTypical.layout;
    this.wiring = cabinetTypical.wiring;
    this.fuseList = cabinetTypical.fuseList;
    this.bom = cabinetTypical.bom;
    this.previewUrl = cabinetTypical.previewUrl;
    this.url = cabinetTypical.url;

    Object.defineProperty(this, 'desingForExAreasString', {
      get() {
        switch (this.designForExAreasType) {
          case DesignForExAreasType.IS:
            return 'IS';
          case DesignForExAreasType.NONIS:
            return 'NON IS';
        }
        return '';
      }
    });

    Object.defineProperty(this, 'nativeToolString', {
      get() {
        switch (this.nativeToolType) {
          case NativeToolType.EB:
            return 'EB';
          case NativeToolType.Eplan:
            return 'Eplan';
          case NativeToolType.E3:
            return 'E3';
        }
        return '';
      }
    });

    Object.defineProperty(this, 'redundancyString', {
      get() {
        switch (this.redundancyType) {
          case YesNoNaType.Yes:
            return 'Yes';
          case YesNoNaType.No:
            return 'No';
          case YesNoNaType.NA:
            return 'N/A';
        }
        return '';
      }
    });

    Object.defineProperty(this, 'equipmentOnDoorString', {
      get() {
        switch (this.equipmentOnDoor) {
          case YesNoNaType.Yes:
            return 'Yes';
          case YesNoNaType.No:
            return 'No';
          case YesNoNaType.NA:
            return 'N/A';
        }
        return '';
      }
    });

    Object.defineProperty(this, 'halogenFreeString', {
      get() {
        switch (this.halogenFree) {
          case YesNoNaType.Yes:
            return 'Yes';
          case YesNoNaType.No:
            return 'No';
          case YesNoNaType.NA:
            return 'N/A';
        }
        return '';
      }
    });

    Object.defineProperty(this, 'bomString', {
      get() {
        switch (this.bom) {
          case YesNoNaType.Yes:
            return 'Yes';
          case YesNoNaType.No:
            return 'No';
          case YesNoNaType.NA:
            return 'N/A';
        }
        return '';
      }
    });

    Object.defineProperty(this, 'fuseListString', {
      get() {
        switch (this.fuseList) {
          case YesNoNaType.Yes:
            return 'Yes';
          case YesNoNaType.No:
            return 'No';
          case YesNoNaType.NA:
            return 'N/A';
        }
        return '';
      }
    });

    Object.defineProperty(this, 'wiringString', {
      get() {
        switch (this.wiring) {
          case YesNoNaType.Yes:
            return 'Yes';
          case YesNoNaType.No:
            return 'No';
          case YesNoNaType.NA:
            return 'N/A';
        }
        return '';
      }
    });

    Object.defineProperty(this, 'layoutString', {
      get() {
        switch (this.layout) {
          case YesNoNaType.Yes:
            return 'Yes';
          case YesNoNaType.No:
            return 'No';
          case YesNoNaType.NA:
            return 'N/A';
        }
        return '';
      }
    });

  }
}



