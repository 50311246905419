import {Person, PersonViewType} from '@microsoft/mgt-react';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useLocation} from 'react-router-dom';
import styles from './SearchResult.module.scss';
import {useSearch} from '../../../hooks/services/useSearch';
import {useUnit} from '../../../hooks/services/useUnit';
import {ProcessTree, RolePermission, Unit} from '../../../models';
import {SearchResult} from '../../../models/SearchResult';
import {DictionariesContext} from '../../../providers';
import {
  BooleanColumn,
  DateTimeColumn,
  DropdownColumn,
  OpenColumn,
  RichtextColumn,
  SelectColumn,
  UserDtoColumn
} from '../../common/EnhancedDetailsList';
import {MultiselectColumn} from '../../common/EnhancedDetailsList/ColumnTypes/MultiselectColumn';
import {ColumnKey, IEnhancedColumn} from '../../common/EnhancedDetailsList/IEnhancedDetailsList';
import {OpenPDCColumn} from '../../common/EnhancedDetailsList/ColumnTypes/OpenPDCColumn';
import {TooltipHost, Icon} from '@fluentui/react';
import {ApiResponse} from '../../../services/ApiResponseType';
import {useService} from '../../../hooks';
import {SearchLinksListColumn} from '../../common/EnhancedDetailsList/ColumnTypes/SearchLinksListColumn';
import {UnitToSearch} from '../../../models/UnitToSearch';
import {StringArrayColumn} from '../../common/EnhancedDetailsList/ColumnTypes/StringArrayColumn';
import {SearchType} from '../../../services/generated';
import {OpenCopyColumn} from '../../common/EnhancedDetailsList/ColumnTypes/OpenCopyColumn';
import {OpenPDCCabinetColumn} from '../../common/EnhancedDetailsList/ColumnTypes/OpenPDCCabinetColumn';
import { useAdmin } from '../../../hooks/services/useAdmin';

export const useSearchResultsColumns = () => {
  const {search} = useLocation();
  const {globalFiltersFunction} = useContext(DictionariesContext);
  const searchValue = new URLSearchParams(search).get('search');
  const unitId = new URLSearchParams(search).get('unitId');
  const {getSearchResults} = useSearch();
  const {getMenuProcessesPublishedForUnit, getUserPermissionsPage} = useService();
  const {getUnit, getUnits} = useUnit();
  const {getGlobalSettings} = useAdmin()

  const [isDocumentLoading, setIsDocumentLoading] = useState(false);
  const [isPDCLoading, setIsPDCLoading] = useState(false);
  const [isProcessLoading, setIsProcessLoading] = useState(false);
  const [isAILoading, setIsAILoading] = useState(false);
  const [isAOLoading, setIsAOLoading] = useState(false);
  const [isExternalLinkLoading, setIsExternalLinkLoading] = useState(false);
  const [isCabinetTypicalLoading, setIsCabinetTypicalLoading] = useState(false);

  const [searchDocumentResults, setDocumentResults] = useState<SearchResult[]>([]);
  const [searchPDCResults, setPDCResults] = useState<SearchResult[]>([]);
  const [searchProcessResults, setProcessResults] = useState<SearchResult[]>([]);
  const [searchAIResults, setAIResults] = useState<SearchResult[]>([]);
  const [searchAOResults, setAOResults] = useState<SearchResult[]>([]);
  const [searchExternalLinkResults, setExternalLinkResults] = useState<SearchResult[]>([]);
  const [searchCabinetTypicalResults, setCabinetTypicalResults] = useState<SearchResult[]>([]);

  const [filteredDocumentResults, setFilteredDocumentResults] = useState<SearchResult[]>([]);
  const [filteredProcessResults, setFilteredProcessResults] = useState<SearchResult[]>([]);
  const [filteredExternalLinkResults, setFilteredExternalLinkResults] = useState<SearchResult[]>(
    []
  );

  const [unit, setUnit] = useState<Unit | null>(null);
  const [unitWithPreparedTitle, setUnitWithPreparedTitle] = useState<UnitToSearch | undefined>();
  const [preselectedUnitTitle, setPreselectedUnitTitle] = useState<string | undefined>();
  const [preselectedOperatingUnit, setPreselectedOperatingUnit] = useState<string[] | undefined>();
  const [publishedProcessesForUnit, setPublishedProcessesForUnit] = useState<
    ApiResponse<ProcessTree[]> | undefined
  >();
  const [unitPermissions, setUnitPermissions] = useState<ApiResponse<RolePermission[]>>();
  const [helmetTitle, setHelmetTitle] = useState<string>('IMS Search page');
  const [cabinetTypicalsFeatureEnabled, setCabinetTypicalsFeatureEnabled] = useState<boolean>(false);


 

 useEffect(() => {
  const fetchSettings = async () => {
    const settingsResponse = await getGlobalSettings();
    if (settingsResponse?.result) {
      const cabinetTypicalsFeatureEnabled = settingsResponse.result.find(
        x => x.name === 'cabinetTypicals'
      );
      if (cabinetTypicalsFeatureEnabled) {
        setCabinetTypicalsFeatureEnabled(cabinetTypicalsFeatureEnabled.value === 'true');
      }
    }
  };
  fetchSettings();
}, [getGlobalSettings]);

  const columnsCabinets = useMemo(() => {
    const cols: IEnhancedColumn<SearchResult>[] = [
      {
        key: ColumnKey.Url,
        name: '',
        data: OpenPDCCabinetColumn
      },
      {
        key: ColumnKey.TypicalName,
        name: 'Typical Name',
        minWidth: 330,
        flexGrow: 1,
        exportToExcel: true,
        onExportToExcel: (item, column) => {
          return {
            text: item.typicalName,
            link: item.url
          };
        }
      },
      {
        key: ColumnKey.CabinetTypicalDocuments,
        name: 'Documents',
        flexGrow: 1,
        minWidth: 300,
        data: StringArrayColumn
      },
      {
        key: ColumnKey.NativeToolString,
        name: 'Native Tool',
        data: SelectColumn,
        minWidth: 135,
        exportToExcel: true
      },
      {
        key: ColumnKey.ComponentCatalogName,
        name: 'Component Catalog Name',
        minWidth: 235,
        exportToExcel: true
      },
      {
        key: ColumnKey.DesingForExAreasString,
        name: 'Design For Ex Areas',
        data: SelectColumn,
        minWidth: 200,
        exportToExcel: true
      },
      {
        key: ColumnKey.HalogenFreeString,
        name: 'Halogen Free',
        data: SelectColumn,
        minWidth: 150,
        exportToExcel: true
      },
      {
        key: ColumnKey.RedundancyString,
        name: 'Redundancy',
        data: SelectColumn,
        minWidth: 135,
        exportToExcel: true
      },
      {
        key: ColumnKey.Description,
        name: 'Description',
        minWidth: 330,
        exportToExcel: true
      },
      {
        key: ColumnKey.Type,
        name: 'Type',
        data: SelectColumn,
        minWidth: 135,
        exportToExcel: true
      },
      {
        key: ColumnKey.Plinth,
        name: 'Plinth',
        minWidth: 105,
        exportToExcel: true
      },
      {
        key: ColumnKey.Height,
        name: 'Height',
        minWidth: 105,
        exportToExcel: true
      },
      {
        key: ColumnKey.Width,
        name: 'Width',
        minWidth: 105,
        exportToExcel: true
      },
      {
        key: ColumnKey.Depth,
        name: 'Depth',
        minWidth: 105,
        exportToExcel: true
      },
      {
        key: ColumnKey.Ip,
        name: 'IP',
        data: SelectColumn,
        minWidth: 105,
        exportToExcel: true
      },

      {
        key: ColumnKey.CabinetAccess,
        name: 'Cabinet Access (doors)',
        minWidth: 205,
        exportToExcel: true
      },

      {
        key: ColumnKey.IOcount,
        name: 'IO Count',
        minWidth: 105,
        exportToExcel: true
      },
      {
        key: ColumnKey.NumberOfControllers,
        name: 'Number Of Controllers',
        minWidth: 205,
        exportToExcel: true
      },
      {
        key: ColumnKey.EquipmentOnDoorString,
        name: 'Equipment On Door',
        data: SelectColumn,
        minWidth: 200,
        exportToExcel: true
      },
      {
        key: ColumnKey.CableEntry,
        name: 'Cable Entry',
        minWidth: 150,
        exportToExcel: true
      },
      {
        key: ColumnKey.PowerSupply,
        name: 'Power Supply',
        minWidth: 150,
        exportToExcel: true
      },
      {
        key: ColumnKey.PowerSupplyProducer,
        name: 'Power Supply Producer',
        minWidth: 200,
        exportToExcel: true
      },
      {
        key: ColumnKey.CabinetCatalogNo,
        name: 'Cabinet Catalog No',
        minWidth: 205,
        exportToExcel: true
      },
      {
        key: ColumnKey.ManufacturedBy,
        name: 'Manufactured By',
        minWidth: 215,
        exportToExcel: true
      },
      {
        key: ColumnKey.CabinetFrame,
        name: 'Cabinet Frame',
        minWidth: 200,
        exportToExcel: true
      },
      {
        key: ColumnKey.ControlSystem,
        name: 'Control System',
        minWidth: 200,
        exportToExcel: true
      },
      {
        key: ColumnKey.LayoutString,
        name: 'Layout',
        data: SelectColumn,
        minWidth: 110,
        exportToExcel: true
      },
      {
        key: ColumnKey.WiringString,
        name: 'Wiring',
        data: SelectColumn,
        minWidth: 110,
        exportToExcel: true
      },
      {
        key: ColumnKey.FuseListString,
        name: 'Fuse List',
        data: SelectColumn,
        minWidth: 110,
        exportToExcel: true
      },
      {
        key: ColumnKey.BomString,
        name: 'BOM',
        data: SelectColumn,
        minWidth: 110,
        exportToExcel: true
      }
    ];
    return cols;
  }, []);

  const columnsProcess = useMemo(() => {
    const cols: IEnhancedColumn<SearchResult>[] = [
      {
        key: ColumnKey.PreparedUrl,
        name: '',
        data: OpenColumn
      },
      {
        key: ColumnKey.Title,
        name: 'Title',
        minWidth: 200,
        flexGrow: 1
      },
      {
        key: ColumnKey.UnitTitle,
        name: 'Unit',
        data: DropdownColumn,
        minWidth: 200,
        defaultFilterValue: preselectedUnitTitle ? [preselectedUnitTitle] : undefined,
        onFilterChange: () => setUnit(null)
      },
      {
        key: 'specialities',
        name: 'Specialty',
        minWidth: 120,
        flexGrow: 1,
        data: MultiselectColumn
      },
      {
        key: 'projectSizeCategories',
        name: 'Project Size Category',
        minWidth: 200,
        flexGrow: 1,
        data: MultiselectColumn
      },
      {
        key: 'industries',
        name: 'Industry',
        minWidth: 200,
        flexGrow: 1,
        data: MultiselectColumn
      },
      {
        key: ColumnKey.FunctionString,
        name: 'Function',
        minWidth: 200,
        flexGrow: 1,
        data: SelectColumn
      },
      {
        key: ColumnKey.ProcessOwner,
        name: 'Process Owner',
        minWidth: 250,
        flexGrow: 1,
        data: UserDtoColumn,
        onRender: (item: SearchResult) =>
          item.processOwner ? (
            <Person
              userId={item.processOwner.azureId}
              view={PersonViewType.oneline}
              avatar-size="small"
              avatarType="initials"
            />
          ) : (
            <></>
          )
      }
    ];
    return cols;
  }, [preselectedUnitTitle]);
  const columnsDocuments = useMemo(() => {
    const cols: IEnhancedColumn<SearchResult>[] = [
      {
        key: ColumnKey.PreparedUrl,
        name: '',
        data: OpenPDCColumn
      },
      {
        key: ColumnKey.Title,
        name: 'Title',
        minWidth: 330,
        flexGrow: 1
      },
      {
        key: ColumnKey.DocumentId,
        exportToExcel: true,
        name: 'Document ID',
        minWidth: 135,
        onRender(item) {
          return (
            <>
              {item.documentId}
              {item.documentInReview && (
                <TooltipHost content="New revision is pending">
                  <Icon styles={{root: {paddingLeft: '5px', color: 'red'}}} iconName="Sync" />
                </TooltipHost>
              )}
            </>
          );
        }
      },
      {
        key: ColumnKey.UnitTitle,
        name: 'Unit',
        data: DropdownColumn,
        minWidth: 200,
        defaultFilterValue: preselectedUnitTitle ? [preselectedUnitTitle] : undefined,
        onFilterChange: filter => {
          setUnit(null);
        }
      },
      {
        key: ColumnKey.OperatingUnit,
        name: 'Operating Unit',
        minWidth: 150,
        flexGrow: 1,
        data: SelectColumn
      },
      {
        key: 'processes',
        name: 'Process(es)',
        minWidth: 300,
        data: SearchLinksListColumn,
        onRender: (item: SearchResult) => (
          <>
            {unitWithPreparedTitle
              ? item.processes
                  ?.filter(x => x.unitId === unitWithPreparedTitle.id)
                  .map(value => {
                    return (
                      <a
                        key={`${value.unitId}-${value.id}`}
                        href={`/unit/${value.unitId}/process/${value.id}`}
                        className={styles.link}
                        target="_blank"
                        rel="noreferrer">
                        {value.title}
                      </a>
                    );
                  })
              : item.processes?.map(value => {
                  return (
                    <a
                      key={`${value.unitId}-${value.id}`}
                      href={`/unit/${value.unitId}/process/${value.id}`}
                      className={styles.link}
                      target="_blank"
                      rel="noreferrer">
                      {value.title}
                    </a>
                  );
                })}
          </>
        )
      },
      {
        key: ColumnKey.TypeString,
        name: 'Content Type',
        data: SelectColumn,
        minWidth: 155
      },
      {
        key: ColumnKey.DocumentKind,
        name: 'Document Kind',
        minWidth: 150,
        flexGrow: 1,
        data: SelectColumn
      },
      {
        key: ColumnKey.FunctionString,
        name: 'Function',
        minWidth: 120,
        flexGrow: 1,
        data: SelectColumn
      },
      {
        key: 'businessLines',
        name: 'Business Line',
        minWidth: 120,
        flexGrow: 1,
        data: MultiselectColumn
      },
      {
        key: 'industries',
        name: 'Industry',
        minWidth: 120,
        flexGrow: 1,
        data: MultiselectColumn
      },
      {
        key: 'specialities',
        name: 'Specialty',
        minWidth: 120,
        flexGrow: 1,
        data: MultiselectColumn
      },
      {
        key: 'projectSizeCategories',
        name: 'Project Size Category',
        minWidth: 200,
        flexGrow: 1,
        data: MultiselectColumn
      },
      {
        key: ColumnKey.DocumentOwner,
        name: 'Document Owner',
        minWidth: 180,
        flexGrow: 1,
        data: SelectColumn
      },
      {
        key: ColumnKey.ApprovedDate,
        name: 'Approved Date',
        minWidth: 145,
        data: DateTimeColumn
      },
      {
        key: 'revision',
        name: 'Revision',
        data: SelectColumn,
        minWidth: 110
      },
      {
        key: ColumnKey.LanguageShort,
        name: 'Language',
        minWidth: 120,
        flexGrow: 1,
        data: SelectColumn
      },
      {
        key: ColumnKey.RelevantTo,
        name: 'Related To',
        minWidth: 135
      },
      {
        key: ColumnKey.Auditable,
        name: 'Auditable',
        minWidth: 120,
        flexGrow: 1,
        data: BooleanColumn,
        onRender: (item: SearchResult) =>
          item.type === SearchType.Process || item.type === SearchType.ExternalLink ? (
            <></>
          ) : (
            <>{item.auditable ? 'True' : 'False'}</>
          )
      },
      {
        key: ColumnKey.SecurityLevel,
        name: 'Security Level',
        minWidth: 150,
        flexGrow: 1,
        data: SelectColumn
      },
      {
        key: ColumnKey.KeyWords,
        name: 'Keywords',
        flexGrow: 1,
        minWidth: 190,
        data: StringArrayColumn
      }
    ];
    return cols;
  }, [preselectedUnitTitle, unitWithPreparedTitle]);
  const columnsAIandAO = useMemo(() => {
    const cols: IEnhancedColumn<SearchResult>[] = [
      {
        key: ColumnKey.PreparedUrl,
        name: '',
        data: OpenColumn
      },
      {
        key: ColumnKey.Title,
        name: 'Title',
        minWidth: 200,
        flexGrow: 1
      },
      {
        key: ColumnKey.UnitTitle,
        name: 'Unit',
        data: DropdownColumn,
        minWidth: 200,
        defaultFilterValue: preselectedUnitTitle ? [preselectedUnitTitle] : undefined,
        onFilterChange: filter => {
          setUnit(null);
        }
      },
      {
        key: ColumnKey.TypeString,
        name: 'Type',
        data: SelectColumn,
        minWidth: 155
      },
      {
        key: ColumnKey.OperatingUnit,
        name: 'Operating Unit',
        minWidth: 200,
        flexGrow: 1,
        data: SelectColumn
      },
      {
        key: 'projectSizeCategories',
        name: 'Project Size Category',
        minWidth: 200,
        flexGrow: 1,
        data: MultiselectColumn
      },
      {
        key: ColumnKey.FunctionString,
        name: 'Function',
        minWidth: 200,
        flexGrow: 1,
        data: SelectColumn
      },
      {
        key: 'specialities',
        name: 'Specialty',
        minWidth: 200,
        flexGrow: 1,
        data: MultiselectColumn
      },
      {
        key: 'processes',
        name: 'Process(es)',
        minWidth: 300,
        data: SearchLinksListColumn,
        onRender: (item: SearchResult) => (
          <>
            {unitWithPreparedTitle
              ? item.processes
                  ?.filter(x => x.unitId === unitWithPreparedTitle.id)
                  .map(value => {
                    return (
                      <a
                        key={`${value.unitId}-${value.id}`}
                        href={`/unit/${value.unitId}/process/${value.id}`}
                        className={styles.link}
                        target="_blank"
                        rel="noreferrer">
                        {value.title}
                      </a>
                    );
                  })
              : item.processes?.map(value => {
                  return (
                    <a
                      key={`${value.unitId}-${value.id}`}
                      href={`/unit/${value.unitId}/process/${value.id}`}
                      className={styles.link}
                      target="_blank"
                      rel="noreferrer">
                      {value.title}
                    </a>
                  );
                })}
          </>
        )
      }
    ];
    return cols;
  }, [preselectedUnitTitle, unitWithPreparedTitle]);

  const columnsPDC = useMemo(() => {
    const cols: IEnhancedColumn<SearchResult>[] = [
      {
        key: ColumnKey.PreparedUrl,
        name: '',
        data: OpenCopyColumn
      },
      {
        key: ColumnKey.Title,
        name: 'Title',
        minWidth: 200,
        flexGrow: 1
      },
      {
        key: ColumnKey.DocumentId,
        name: 'DocumentId',
        minWidth: 135,
        flexGrow: 1
      },
      {
        key: ColumnKey.Library,
        name: 'Library',
        data: DropdownColumn,
        minWidth: 200,
        onFilterChange: () => setUnit(null)
      },
      {
        key: ColumnKey.OperatingUnit,
        name: 'Operating Unit',
        minWidth: 150,
        flexGrow: 1,
        defaultFilterValue: preselectedOperatingUnit ? preselectedOperatingUnit : undefined,
        data: SelectColumn
      },
      {
        key: ColumnKey.DocumentOwner,
        name: 'Document Owner',
        minWidth: 430,
        flexGrow: 1,
        data: SelectColumn
      },
      {
        key: ColumnKey.Content,
        name: 'Content',
        minWidth: 730,
        flexGrow: 1,
        data: RichtextColumn
      }
    ];
    return cols;
  }, [preselectedOperatingUnit]);

  const columnsLinks = useMemo(() => {
    const cols: IEnhancedColumn<SearchResult>[] = [
      {
        key: ColumnKey.PreparedUrl,
        name: '',
        data: OpenColumn
      },
      {
        key: ColumnKey.Title,
        name: 'Title',
        minWidth: 200,
        flexGrow: 1
      },
      {
        key: ColumnKey.UnitTitle,
        name: 'Unit',
        data: DropdownColumn,
        minWidth: 200,
        defaultFilterValue: preselectedUnitTitle ? [preselectedUnitTitle] : undefined,
        onFilterChange: filter => {
          setUnit(null);
        }
      },
      {
        key: ColumnKey.TypeString,
        name: 'Type',
        data: SelectColumn,
        minWidth: 155
      },
      {
        key: ColumnKey.OperatingUnit,
        name: 'Operating Unit',
        minWidth: 200,
        flexGrow: 1,
        data: SelectColumn
      },
      {
        key: 'processes',
        name: 'Process(es)',
        minWidth: 300,
        data: SearchLinksListColumn,
        onRender: (item: SearchResult) => (
          <>
            {unitWithPreparedTitle
              ? item.processes
                  ?.filter(x => x.unitId === unitWithPreparedTitle.id)
                  .map(value => {
                    return (
                      <a
                        key={`${value.unitId}-${value.id}`}
                        href={`/unit/${value.unitId}/process/${value.id}`}
                        className={styles.link}
                        target="_blank"
                        rel="noreferrer">
                        {value.title}
                      </a>
                    );
                  })
              : item.processes?.map(value => {
                  return (
                    <a
                      key={`${value.unitId}-${value.id}`}
                      href={`/unit/${value.unitId}/process/${value.id}`}
                      className={styles.link}
                      target="_blank"
                      rel="noreferrer">
                      {value.title}
                    </a>
                  );
                })}
          </>
        )
      },
      {
        key: ColumnKey.ExternalLinkTypeString,
        name: 'External Link Type',
        data: SelectColumn,
        minWidth: 165
      },
      {
        key: ColumnKey.IsExternal,
        name: 'Is External',
        tooltip: 'Page or tool outside of ABB',
        minWidth: 140,
        data: BooleanColumn
      }
    ];
    return cols;
  }, [preselectedUnitTitle, unitWithPreparedTitle]);

  const prepareUnits = useCallback((units: Unit[] | null): UnitToSearch[] => {
    let result: UnitToSearch[] = [];
    units?.forEach(x => {
      const id = x.id;
      const title =
        x.parentTitle && x.unitTypeId === 2 ? `${x.parentTitle} | ${x.title}` : x.title || null;
      result.push(new UnitToSearch(id, title));
    });

    return result;
  }, []);

  useEffect(() => {
    (async () => {
      const unitIdNum = Number(unitId);
      const unitsResponse = await getUnits();
      const searchUnits = prepareUnits(unitsResponse.result);

      if (unitId && unitIdNum !== unit?.id) {
        const unitResponse = unitsResponse.result?.find(x => x.id === unitIdNum);
        if (unitResponse) {
          setUnit(unitResponse);
          const preselectedUnitTitleFromRequest =
            unitResponse.parentTitle && unitResponse.unitTypeId === 2
              ? `${unitResponse.parentTitle} | ${unitResponse.title}`
              : unitResponse.title || undefined;

          const unitWithPreparedTitle = searchUnits.find(x => x.id === unitIdNum);
          setUnitWithPreparedTitle(unitWithPreparedTitle);
          setPreselectedUnitTitle(preselectedUnitTitleFromRequest);
          setPreselectedOperatingUnit(unitResponse.pdcOperatingUnit?.split(';'));
        }
      }
    })();
  }, [unitId, getUnit, getUnits, prepareUnits, unit]);

  useEffect(() => {
    (async () => {
      if (!searchValue) return;
      setHelmetTitle(`IMS Search result page for: ${searchValue}`);
      setIsDocumentLoading(true);

      const results = await getSearchResults({keyword: searchValue, type: SearchType.Document});
      setDocumentResults(results.result || []);
      setIsDocumentLoading(false);
    })();
  }, [searchValue, getSearchResults, setDocumentResults, setIsDocumentLoading]);

  useEffect(() => {
    (async () => {
      if (!searchValue) return;
      setHelmetTitle(`IMS Search result page for: ${searchValue}`);
      setIsCabinetTypicalLoading(true);

      const results = await getSearchResults({
        keyword: searchValue,
        type: SearchType.CabinetTypical
      });
      setCabinetTypicalResults(results.result || []);
      setIsCabinetTypicalLoading(false);
    })();
  }, [searchValue, getSearchResults, setCabinetTypicalResults, setIsCabinetTypicalLoading]);

  useEffect(() => {
    (async () => {
      if (!searchValue) return;
      setHelmetTitle(`IMS Search result page for: ${searchValue}`);
      setIsExternalLinkLoading(true);

      const results = await getSearchResults({keyword: searchValue, type: SearchType.ExternalLink});
      setExternalLinkResults(results.result || []);
      setIsExternalLinkLoading(false);
    })();
  }, [searchValue, getSearchResults, setExternalLinkResults, setIsExternalLinkLoading]);

  useEffect(() => {
    (async () => {
      if (!searchValue) return;
      setHelmetTitle(`IMS Search result page for: ${searchValue}`);
      setIsPDCLoading(true);

      const results = await getSearchResults({keyword: searchValue, type: SearchType.PDC});
      setPDCResults(results.result || []);
      setIsPDCLoading(false);
    })();
  }, [searchValue, getSearchResults, setPDCResults, setIsPDCLoading]);

  useEffect(() => {
    (async () => {
      if (!searchValue) return;
      setHelmetTitle(`IMS Search result page for: ${searchValue}`);
      setIsProcessLoading(true);

      const results = await getSearchResults({keyword: searchValue, type: SearchType.Process});
      setProcessResults(results.result || []);
      setIsProcessLoading(false);
    })();
  }, [searchValue, getSearchResults, setProcessResults, setIsProcessLoading]);

  useEffect(() => {
    (async () => {
      if (!searchValue) return;
      setHelmetTitle(`IMS Search result page for: ${searchValue}`);
      setIsAILoading(true);

      const results = await getSearchResults({
        keyword: searchValue,
        type: SearchType.ActivityInput
      });
      setAIResults(results.result || []);
      setIsAILoading(false);
    })();
  }, [searchValue, getSearchResults, setAIResults, setIsAILoading]);

  useEffect(() => {
    (async () => {
      if (!searchValue) return;
      setHelmetTitle(`IMS Search result page for: ${searchValue}`);
      setIsAOLoading(true);

      const results = await getSearchResults({
        keyword: searchValue,
        type: SearchType.ActivityOutput
      });
      setAOResults(results.result || []);
      setIsAOLoading(false);
    })();
  }, [searchValue, getSearchResults, setAOResults, setIsAOLoading]);

  useEffect(() => {
    const processResult = searchProcessResults.filter(globalFiltersFunction);

    setFilteredProcessResults(processResult);
  }, [searchProcessResults, globalFiltersFunction]);

  useEffect(() => {
    const documentsResult = searchDocumentResults.filter(globalFiltersFunction);

    setFilteredDocumentResults(documentsResult);
  }, [searchDocumentResults, globalFiltersFunction]);

  useEffect(() => {
    const linksResult = searchExternalLinkResults.filter(globalFiltersFunction);

    setFilteredExternalLinkResults(linksResult);
  }, [searchExternalLinkResults, globalFiltersFunction]);

  useEffect(() => {
    if (unitId) {
      (async () => {
        const processList = await getMenuProcessesPublishedForUnit(Number(unitId));
        setPublishedProcessesForUnit(processList);
        const permissions = await getUserPermissionsPage(Number(unitId));
        setUnitPermissions(permissions);
      })();
    }
  }, [getMenuProcessesPublishedForUnit, getUserPermissionsPage, unitId]);

  return useMemo(
    () => ({
      columnsDocuments,
      columnsCabinets,
      helmetTitle,
      columnsAIandAO,
      columnsLinks,
      columnsProcess,
      columnsPDC,
      filteredDocumentResults,
      filteredProcessResults,
      filteredExternalLinkResults,
      searchAIResults,
      searchAOResults,
      searchPDCResults,
      searchCabinetTypicalResults,
      isDocumentLoading,
      isPDCLoading,
      isProcessLoading,
      cabinetTypicalsFeatureEnabled,
      isAILoading,
      isAOLoading,
      isExternalLinkLoading,
      isCabinetTypicalLoading,
      unit,
      searchValue,
      publishedProcessesForUnit,
      unitPermissions,
      preselectedUnitTitle
    }),
    [
      columnsDocuments,
      columnsCabinets,
      helmetTitle,
      columnsAIandAO,
      columnsLinks,
      columnsProcess,
      columnsPDC,
      filteredDocumentResults,
      filteredProcessResults,
      filteredExternalLinkResults,
      searchAIResults,
      searchAOResults,
      cabinetTypicalsFeatureEnabled,
      searchPDCResults,
      searchCabinetTypicalResults,
      isDocumentLoading,
      isPDCLoading,
      isProcessLoading,
      isAILoading,
      isAOLoading,
      isCabinetTypicalLoading,
      isExternalLinkLoading,
      unit,
      searchValue,
      publishedProcessesForUnit,
      unitPermissions,
      preselectedUnitTitle
    ]
  );
};
