/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { IDropdownOption } from "@fluentui/react";

/**
 *
 *
 * 0 = EB
 *
 * 1 = Eplan
 * 
 * 2 = E3
 */
export enum NativeToolType {
  EB = 0,
  Eplan = 1,
  E3 = 2,
}

export const nativeToolOption: IDropdownOption[] = [
  {key: 0, text: NativeToolType[0]},
  {key: 1, text: NativeToolType[1]},
  {key: 2, text: NativeToolType[2]}
];
