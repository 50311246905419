import {
  BooleanColumn,
  EnhancedDetailsList,
  OtherColumn,
} from '../../common/EnhancedDetailsList';
import {ColumnKey, IEnhancedColumn, SpecialColumnKey} from '../../common/EnhancedDetailsList/IEnhancedDetailsList';

import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {Helmet} from 'react-helmet';
import * as useUnitFromUnit from '../Unit/useUnit';
import { useCabinetTypicals } from '../../../hooks/services/useCabinetTypicals';
import { CabinetTypicalDocument } from '../../../models/CabinetTypicalDocument';
import { Checkbox, Spinner } from '@fluentui/react';
import { OpenPDCColumn } from '../../common/EnhancedDetailsList/ColumnTypes/OpenPDCColumn';
import styles from './CabinetTypicalDocuments.module.scss';
import { OpenPDCCabinetDocumentColumn } from '../../common/EnhancedDetailsList/ColumnTypes/OpenPDCCabinetDocumentColumn';

interface ICabinetTypicalDocumentsProps {
  cabinetTypicalId: number;
  cabinetTypicalName: string
 }

export const CabinetTypicalDocuments: FC<ICabinetTypicalDocumentsProps> = ({cabinetTypicalId, cabinetTypicalName}) => {
  const {apiMsg} =
    useUnitFromUnit.useUnit();

  const {getCabinetTypicalRelatedDocuments, getCabinetTypicalsDocumentsSelectedFileBlob} = useCabinetTypicals();
  const [selectedCabinetTypicalDocuments, setSelectedCabinetTypicalDocuments] = useState<CabinetTypicalDocument[]>([])
  const [downloadInProgress, setDownloadInProgress] = useState<boolean>(false)

  const downloadSelected = useCallback(() => {
    setDownloadInProgress(true)
    const selectedUrls = selectedCabinetTypicalDocuments.map(item => item.url);
    getCabinetTypicalsDocumentsSelectedFileBlob(selectedUrls).then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${cabinetTypicalName} selected documents.zip`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
      setDownloadInProgress(false)
      setSelectedCabinetTypicalDocuments([])
    })
  }, [selectedCabinetTypicalDocuments,getCabinetTypicalsDocumentsSelectedFileBlob,cabinetTypicalName]);

   const noneSelected = useMemo(() => {

     if(selectedCabinetTypicalDocuments.length)
     {
      return false;
     }

     return true;

   },[selectedCabinetTypicalDocuments])

   const onRowCheck = useCallback(
      (element: CabinetTypicalDocument, isChecked: boolean) => {
        if (isChecked) {
          const items = [...new Set([...selectedCabinetTypicalDocuments, element])];
          setSelectedCabinetTypicalDocuments(items);
        } else {
          const items = selectedCabinetTypicalDocuments.filter(item => item.id !== element.id);
          setSelectedCabinetTypicalDocuments(items);
        }
      },
      [selectedCabinetTypicalDocuments, setSelectedCabinetTypicalDocuments]
    );

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [cabinetTypicalDocuments, setCabinetTypicalDocuments] = useState<CabinetTypicalDocument[]>([]);


  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const cabinetTypicalDocumentsResponse = await getCabinetTypicalRelatedDocuments(cabinetTypicalId);
      if(cabinetTypicalDocumentsResponse && cabinetTypicalDocumentsResponse.result)
      {
        setCabinetTypicalDocuments(cabinetTypicalDocumentsResponse.result);
      }
      setIsLoading(false);
     
    })();
  }, [getCabinetTypicalRelatedDocuments, cabinetTypicalId]);




  const isSmart = useMemo(
    () => typeof new URLSearchParams(document.location.search).get('smart') === 'string',
    []
  );
  const columns = useMemo(() => {
    const cols: IEnhancedColumn<CabinetTypicalDocument>[] = [
      {
        key: SpecialColumnKey.Select,
        name: '',
        data: OtherColumn,
        minWidth: 30,
        onRender(item, index, column) {
          return (
            <Checkbox
              disabled={downloadInProgress}
              className={styles.select}
              onChange={(ev, checked) => onRowCheck(item, !!checked)}
              checked={selectedCabinetTypicalDocuments.some(document => document.id === item.id)}
            />
          );
        }
      },
      {
        key: ColumnKey.Url,
        name: '',
        data: OpenPDCCabinetDocumentColumn
      },
      ...(isSmart
        ? ([
            {
              key: 'id',
              name: 'ID',
              minWidth: 130
            }
          ] as IEnhancedColumn<CabinetTypicalDocument>[])
        : []),
      {
        key: ColumnKey.Name,
        name: 'Name',
        minWidth: 330,
        flexGrow: 1,
        exportToExcel: true,
        onExportToExcel: (item, column) => {
          return {
            text: item.name,
            link: item.url
          };
        }
      },
      {
        key: ColumnKey.CabinetTypicalName,
        name: 'Cabinet Typical Name',
        minWidth: 330,
        flexGrow: 1,
        exportToExcel: true,
      },
      {
        key: ColumnKey.IsLayout,
        name: 'Is Layout',
        data: BooleanColumn,
        minWidth: 150,
        exportToExcel: true
      },
    ];
    return cols;
  }, [isSmart, onRowCheck, selectedCabinetTypicalDocuments,downloadInProgress]);

  return (
    <>
      <div className={`${isLoading ? 'isLoading' : ''} noPaddingTop`}>
        {
          <Helmet>
            <title>IMS Cabinet Typicals List</title>
          </Helmet>
        }
        {isLoading ? (
          <Spinner />
        ) : (
          !apiMsg && (
            <>
              <EnhancedDetailsList<CabinetTypicalDocument, CabinetTypicalDocument, CabinetTypicalDocument>
                columns={columns}
                items={cabinetTypicalDocuments}
                itemsFiltered={false}
                noneSelected={noneSelected}
                pageSize={30}
                downloadSelected
                downloadInProgress={downloadInProgress}
                onDownloadSelected={downloadSelected}
                excelExport
                excelFileName={'Exported Cabinet Typical Documents List'}
              />
            </>
          )
        )}
      </div>
    </>
  );
};
