import {IContextualMenuItem, Spinner} from '@fluentui/react';
import {TheButton} from '../TheButton';
import styles from './SplitCabinetButton.module.scss';
import {useState} from 'react';
import {ModalWithHeader} from '../ModalWithHeader';
import {CabinetTypicalDocuments} from '../../Pages/CabinetTypicalDocuments';
import {on} from 'events';
import {useCabinetTypicals} from '../../../hooks/services/useCabinetTypicals';

interface IProps {
  previewUrl: string;
  url: string;
  extraMenuProps?: IContextualMenuItem[] | undefined;
  cabinetTypicalName: string;
  id: number;
}

//TODO finish it more as component for full usage
export const SplitCabinetButton = ({
  previewUrl,
  extraMenuProps,
  cabinetTypicalName,
  url,
  id
}: IProps) => {
  // https://abb.sharepoint.com/sites/IMS_BU_OGC_Portal/HBU-Americas/LBL-AR/QADocuments/Venta%20de%20Repuestos.docx
  const siteAbsoluteUrlRegex = previewUrl.match(/.+\/sites\/[^/]+\//);
  const siteAbsoluteUrl = siteAbsoluteUrlRegex ? siteAbsoluteUrlRegex[0] : undefined;
  const previewExtensionRegex = previewUrl.match(/\.(\w{2,5})(?:$|\?)/);
  const extensionRegex = url.match(/\.(\w{2,5})(?:$|\?)/);
  const extension = extensionRegex ? extensionRegex[1] : null;
  const [showRelated, setShowRelated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {getCabinetTypicalsFileBlob} = useCabinetTypicals();
  const previewExtension = previewExtensionRegex ? previewExtensionRegex[1] : null;
  const openLink =
    previewExtension === 'pdf' || !previewExtension
      ? previewUrl
      : `${previewUrl}${previewUrl.indexOf('?') === -1 ? '?' : '&'}web=1`;
  const isFile = previewExtension && !['aspx', 'html'].includes(previewExtension);
  const downloadLink =
    siteAbsoluteUrl && isFile
      ? `${siteAbsoluteUrl}/_layouts/download.aspx?SourceUrl=${previewUrl.replace('&', '%26')}`
      : null;
  const isSplit = downloadLink || extraMenuProps?.length;

  const copyLinkValue =
    extension === 'pdf' || !extension ? url : `${url}${url.indexOf('?') === -1 ? '?' : '&'}web=1`;
  const handleOpen = () => {
    setShowRelated(true);
  };

  const handleDownload = () => {
    setIsLoading(true);
    getCabinetTypicalsFileBlob(id).then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${cabinetTypicalName}.zip`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
      setIsLoading(false);
    });
  };

  const onDismiss = () => {
    setShowRelated(false);
  };

  const copyLink: () => void = () => {
    navigator.clipboard.writeText(copyLinkValue);
  };

  return (
    <div className={styles.wrapperBtn}>
      {isLoading ? (
        <Spinner />
      ) : (
        <TheButton
          href={openLink}
          onClick={() => {}}
          target="_blank"
          iconProps={{
            iconName: 'Preview'
          }}
          menuProps={
            isSplit
              ? {
                  items: [
                    ...(downloadLink
                      ? [
                          {
                            key: 'Download Preview',
                            text: 'Download Preview',
                            iconProps: {
                              iconName: 'Installation'
                            },
                            href: downloadLink,
                            target: '_blank'
                          }
                        ]
                      : []),

                    {
                      key: 'Download All',
                      text: 'Download All',
                      iconProps: {
                        iconName: 'CloudDownload'
                      },
                      onClick: () => handleDownload()
                    },
                    {
                      key: 'CopyLink',
                      text: 'Copy Link',
                      iconProps: {
                        iconName: 'Copy'
                      },
                      onClick: () => copyLink()
                    },
                    ...(extraMenuProps?.length ? extraMenuProps.map(item => item) : []),
                    {
                      key: 'ViewRelevantDocument',
                      text: 'View Related Documents',
                      iconProps: {
                        iconName: 'ChangeEntitlements'
                      },
                      onClick: () => handleOpen()
                    }
                  ]
                }
              : undefined
          }
          classNameButton={`${styles.button}${isSplit ? ` ${styles.splitButton}` : ''}`}
          className={styles.button}
          split>
          Preview
        </TheButton>
      )}
      {showRelated && (
        <ModalWithHeader
          header={`${cabinetTypicalName}'s Related Documents`}
          isVisible={showRelated}
          dismiss={onDismiss}>
          <CabinetTypicalDocuments cabinetTypicalName={cabinetTypicalName} cabinetTypicalId={id} />
        </ModalWithHeader>
      )}
    </div>
  );
};
