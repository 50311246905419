import {CancelablePromise, OpenAPI} from './generated';
import {request} from './requestWithBlob';

export class CabinetTypicalBlobService {


  /**
   * @param cabinetId
   * @returns any Success
   * @throws ApiError
   */
  public static getApiGetAllFilesForCabinet(cabinetId: number): CancelablePromise<Blob> {
    return request(OpenAPI, {
      method: 'GET',
      url: '/api/cabinetTypical/{id}/download',
      mediaType: 'application/json',
      path:{
        id: cabinetId
      }
    });
  }

   /**
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
   public static getApiGetAllSelectedCabinetTypicalDocuments(requestBody: string[]): CancelablePromise<Blob> {
    return request(OpenAPI, {
      method: 'POST',
      url: '/api/cabinetTypical/download',
      mediaType: 'application/json',
      body: requestBody, 
    });
  }
}
