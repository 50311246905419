import {useMemo} from 'react';
import {LogMessageType} from '../../services/LogMessagesType';
import {ApiResponsePending} from '../../services/ApiResponseType';
import {useApiService} from '../../services/helpers';
import { CabinetTypicalService } from '../../services/generated/services/CabinetTypicalService';
import { CabinetTypical } from '../../models/CabinetTypical';
import { CabinetTypicalDocument } from '../../models/CabinetTypicalDocument';
import { EditCabinetTypicalDto } from '../../services/generated/models/EditCabinetTypicalDto';
import { CabinetTypicalBlobService } from '../../services/CabinetTypicalBlobService';
import { IMinimalUser } from '../../interfaces';

export const useCabinetTypicals = () => {
  const requestWrapper = useApiService();


  const getCabinetTypicals = (
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<CabinetTypical[]> => {
    return requestWrapper(
      CabinetTypicalService.getCabinetTypicals(),
      result => result.map(c => new CabinetTypical(c)),
      log
    );
  };

  const getCabinetTypicalRelatedDocuments = (
    id: number,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<CabinetTypicalDocument[]> => {
    return requestWrapper(
      CabinetTypicalService.getCabinetTypicalRelatedDocuments(id),
      result => result.map(c => new CabinetTypicalDocument(c)),
      log
    );
  };


  const getCabinetTypicalLatestVersionForEdit = (
    id: number,
    log: LogMessageType = {
      error: true
    }
  ): ApiResponsePending<EditCabinetTypicalDto> => {
    return requestWrapper(
      CabinetTypicalService.getCabinetTypicalForEdit(id),
      result => result,
      log
    );
  };

    const updateCabinetTypical = async (
      cabinetTypicalId: number,
      cabinetTypical: EditCabinetTypicalDto,
      log: LogMessageType = {
        error: true,
        success: true,
        successMessage: `Cabinet Typical (${cabinetTypicalId}) updated successfully`
      }
    ): ApiResponsePending<boolean> => {
      return await requestWrapper(
        CabinetTypicalService.putCabinetTypical(cabinetTypicalId, cabinetTypical),
        (result, apiCode) => !apiCode,
        log
      );
    };


     const getCabinetTypicalsFileBlob = (
        id: number,
        log: LogMessageType = {
          error: true
        }
      ): Promise<Blob> => {
        return CabinetTypicalBlobService.getApiGetAllFilesForCabinet(id);
      };


      const getCabinetTypicalsDocumentsSelectedFileBlob = (
        requestBody: string[],
        log: LogMessageType = {
          error: true
        }
      ): Promise<Blob> => {
        return CabinetTypicalBlobService.getApiGetAllSelectedCabinetTypicalDocuments(requestBody);
      };


      const getCabinetTypicalsEditors = async (
          log: LogMessageType = {
            error: true
          }
        ): ApiResponsePending<IMinimalUser[]> => {
          return await requestWrapper(
            CabinetTypicalService.getApiCabinetTypicalsEditors(),
            result =>
              result.map(item => ({
                id: item.id,
                azureId: item.azureId,
                userPrincipalName: item.userPrincipalName || ''
              })),
            log
          );
        };

         const updateCabinetTypicalEditors = async (
            data: string[],
            log: LogMessageType = {
              error: true,
              success: true,
              successMessage: 'Cabinet Typicals editors updated successfully'
            }
          ): ApiResponsePending<boolean> => {
            return await requestWrapper(
              CabinetTypicalService.putApiCabinetTypicalsEditors(data),
              (result, apiCode) => !apiCode,
              log
            );
          };

  

  return useMemo(
    () => ({
      getCabinetTypicals,
      getCabinetTypicalsEditors,
      updateCabinetTypicalEditors,
      getCabinetTypicalsFileBlob,
      getCabinetTypicalRelatedDocuments,
      getCabinetTypicalLatestVersionForEdit,
      updateCabinetTypical,
      getCabinetTypicalsDocumentsSelectedFileBlob,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};
