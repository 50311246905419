import {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {useCabinetTypicals} from '../../../../hooks/services/useCabinetTypicals';
import {Helmet} from 'react-helmet';
import {Dropdown, Spinner, TextField} from '@fluentui/react';
import {EditCabinetTypicalDto} from '../../../../services/generated/models/EditCabinetTypicalDto';
import styles from './EditCabinetTypical.module.scss';
import {nativeToolOption} from '../../../../services/generated/models/NativeToolType';
import {designForExAreasOption} from '../../../../services/generated/models/DesignForExAreasType';
import {yesNoNaOption} from '../../../../services/generated/models/YesNoNaType';
import {TheButton} from '../../../common/TheButton';


interface IEditCabinetTypical {
  cabinetTypicalId: number;
  setIsEditModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  refreshDocuments: () => void;
}

export const EditCabinetTypical: FC<IEditCabinetTypical> = ({cabinetTypicalId, setIsEditModalVisible, refreshDocuments}) => {
  const {getCabinetTypicalLatestVersionForEdit, updateCabinetTypical} = useCabinetTypicals();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [cabinetTypicalTitle, setCabinetTypicalTitle] = useState<string | null>(null);
  const [nativeTool, setNativeTool] = useState<number>(0);
  const [designForExAreas, setDesignForExAreas] = useState<number>(0);
  const [redundancy, setRedundancy] = useState<number>(0);
  const [halogenFree, setHalogenFree] = useState<number>(0);
  const [layout, setLayout] = useState<number>(0);
  const [wiring, setWiring] = useState<number>(0);
  const [fuseList, setFuseList] = useState<number>(0);
  const [bom, setBom] = useState<number>(0);
  const [equipmentOnDoor, setEquipmentOnDoor] = useState<number>(0);
  const [versionOfNativeTool, setVersionOfNativeTool] = useState<string | null>(null);
  const [storageOfNativeFile, setStorageOfNativeFile] = useState<string | null>(null);
  const [componentCatalogName, setComponentCatalogName] = useState<string | null>(null);
  const [description, setDescription] = useState<string | null>(null);
  const [type, setType] = useState<string | null>(null);
  const [plinth, setPlinth] = useState<string | null>(null);
  const [height, setHeight] = useState<string | null>(null);
  const [width, setWidth] = useState<string | null>(null);
  const [depth, setDepth] = useState<string | null>(null);
  const [ip, setIp] = useState<string | null>(null);
  const [cabinetAccess, setCabinetAccess] = useState<string | null>(null);
  const [ioCount, setIoCount] = useState<string | null>(null);
  const [numberOfControllers, setNumberOfControllers] = useState<string | null>(null);
  const [cableEntry, setCableEntry] = useState<string | null>(null);
  const [powerSupply, setPowerSupply] = useState<string | null>(null);
  const [powerSupplyProducer, setPowerSupplyProducer] = useState<string | null>(null);
  const [cabinetCatalogNo, setCabinetCatalogNo] = useState<string | null>(null);
  const [manufacturedBy, setManufacturedBy] = useState<string | null>(null);
  const [cabinetFrame, setCabinetFrame] = useState<string | null>(null);
  const [controlSystem, setControlSystem] = useState<string | null>(null);
  const [iOCountErrorMessage, setIoCountErrorMessage] = useState<string | undefined>(undefined);
  const [numberOfControllersErrorMessage, setNumberOfControllesErrorMessage] = useState<string | undefined>(undefined);

  const onSubmit = useCallback(async () => {
    setIsLoading(true);
    var data: EditCabinetTypicalDto = {
      id: cabinetTypicalId,
      typicalName: cabinetTypicalTitle,
      nativeToolType: nativeTool,
      versionOfNativeTool: versionOfNativeTool,
      storageOfNativeFile: storageOfNativeFile,
      componentCatalogName: componentCatalogName,
      designForExAreasType: designForExAreas,
      halogenFree: halogenFree,
      redundancyType: redundancy,
      description: description,
      type: type,
      plinth: plinth,
      height: height,
      width: width,
      depth: depth,
      ip: ip,
      cabinetAccess: cabinetAccess,
      ioCount: ioCount != null ? +ioCount : null,
      numberOfControllers: numberOfControllers != null ? +numberOfControllers : null,
      equipmentOnDoor: equipmentOnDoor,
      cableEntry: cableEntry,
      powerSupply: powerSupply,
      powerSupplyProducer: powerSupplyProducer,
      cabinetCatalogNo: cabinetCatalogNo,
      manufacturedBy: manufacturedBy,
      cabinetFrame: cabinetFrame,
      controlSystem: controlSystem,
      layout: layout,
      wiring: wiring,
      fuseList: fuseList,
      bom: bom
    };

    await updateCabinetTypical(cabinetTypicalId, data);

    setIsLoading(false);
    setIsEditModalVisible(false)
    refreshDocuments();
  }, [
    updateCabinetTypical,
    refreshDocuments,
    setIsEditModalVisible,
    cabinetTypicalId,
    nativeTool,
    versionOfNativeTool,
    storageOfNativeFile,
    componentCatalogName,
    designForExAreas,
    halogenFree,
    redundancy,
    description,
    type,
    plinth,
    height,
    width,
    depth,
    ip,
    cabinetAccess,
    ioCount,
    numberOfControllers,
    equipmentOnDoor,
    cableEntry,
    powerSupply,
    powerSupplyProducer,
    cabinetCatalogNo,
    manufacturedBy,
    cabinetFrame,
    controlSystem,
    layout,
    wiring,
    fuseList,
    bom,
    cabinetTypicalTitle
  ]);

  const onCloseModal = () => {
    setIsEditModalVisible(false);
  };

  const isOnlyNumbers = (value: string) => {
    return /^\d+$/.test(value);
  };

  const handleNativeTool = (option: number) => {
    setNativeTool(option);
  };

  const handleIOCount = (value: string | null) => {
    setIoCountErrorMessage(undefined);
    setIoCount(value);
    if (value != null && isOnlyNumbers(value) === false) {
      setIoCountErrorMessage('Only numbers are allowed');
    }
  };

  const handleNumbersOfControllers = (value: string | null) => {
    setNumberOfControllesErrorMessage(undefined);
    setNumberOfControllers(value);
    if (value != null && isOnlyNumbers(value) === false) {
      setNumberOfControllesErrorMessage('Only numbers are allowed');
    }
  };

  const errorExists = useMemo(() => {
    if(iOCountErrorMessage || numberOfControllersErrorMessage) {
      return true;
    }
    return false
  }, [iOCountErrorMessage, numberOfControllersErrorMessage]);

  const handleDesignForExAreas = (option: number) => {
    setDesignForExAreas(option);
  };

  const handleRedundancy = (option: number) => {
    setRedundancy(option);
  };

  const handleHalogenFree = (option: number) => {
    setHalogenFree(option);
  };

  const handleLayout = (option: number) => {
    setLayout(option);
  };

  const handleWiring = (option: number) => {
    setWiring(option);
  };

  const handleFuseList = (option: number) => {
    setFuseList(option);
  };

  const handleBOM = (option: number) => {
    setBom(option);
  };

  const handleEquipmentOnDoor = (option: number) => {
    setEquipmentOnDoor(option);
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const cabinetTypicalForEdit = await getCabinetTypicalLatestVersionForEdit(cabinetTypicalId);
      if (cabinetTypicalForEdit && cabinetTypicalForEdit.result) {
        setCabinetTypicalTitle(cabinetTypicalForEdit.result.typicalName);
        setNativeTool(cabinetTypicalForEdit.result.nativeToolType);
        setDesignForExAreas(cabinetTypicalForEdit.result.designForExAreasType);
        setRedundancy(cabinetTypicalForEdit.result.redundancyType);
        setHalogenFree(cabinetTypicalForEdit.result.halogenFree);
        setLayout(cabinetTypicalForEdit.result.layout);
        setWiring(cabinetTypicalForEdit.result.wiring);
        setFuseList(cabinetTypicalForEdit.result.fuseList);
        setBom(cabinetTypicalForEdit.result.bom);
        setEquipmentOnDoor(cabinetTypicalForEdit.result.equipmentOnDoor);
        setVersionOfNativeTool(cabinetTypicalForEdit.result.versionOfNativeTool);
        setStorageOfNativeFile(cabinetTypicalForEdit.result.storageOfNativeFile);
        setComponentCatalogName(cabinetTypicalForEdit.result.componentCatalogName);
        setDescription(cabinetTypicalForEdit.result.description);
        setType(cabinetTypicalForEdit.result.type);
        setPlinth(cabinetTypicalForEdit.result.plinth);
        setHeight(cabinetTypicalForEdit.result.height);
        setWidth(cabinetTypicalForEdit.result.width);
        setDepth(cabinetTypicalForEdit.result.depth);
        setIp(cabinetTypicalForEdit.result.ip);
        setCabinetAccess(cabinetTypicalForEdit.result.cabinetAccess);
        setIoCount(
          cabinetTypicalForEdit.result.ioCount
            ? cabinetTypicalForEdit.result.ioCount.toString()
            : null
        );
        setNumberOfControllers(
          cabinetTypicalForEdit.result.numberOfControllers
            ? cabinetTypicalForEdit.result.numberOfControllers.toString()
            : null
        );
        setCableEntry(cabinetTypicalForEdit.result.cableEntry);
        setPowerSupply(cabinetTypicalForEdit.result.powerSupply);
        setPowerSupplyProducer(cabinetTypicalForEdit.result.powerSupplyProducer);
        setCabinetCatalogNo(cabinetTypicalForEdit.result.cabinetCatalogNo);
        setManufacturedBy(cabinetTypicalForEdit.result.manufacturedBy);
        setCabinetFrame(cabinetTypicalForEdit.result.cabinetFrame);
        setControlSystem(cabinetTypicalForEdit.result.controlSystem);
      }
      setIsLoading(false);
    })();
  }, [getCabinetTypicalLatestVersionForEdit, cabinetTypicalId]);

  return (
    <>
      <div className={`${isLoading ? 'isLoading' : ''} noPaddingTop`}>
        {
          <Helmet>
            <title>{`IMS Edit Cabinet Typical: ${cabinetTypicalTitle}`} </title>
          </Helmet>
        }
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <TextField label="Title" value={cabinetTypicalTitle || undefined} readOnly disabled />
            <div className={styles.fieldsContainer}>
              <Dropdown
                label="Native Tool"
                className={styles.dropdown}
                onChange={(e, option) => {
                  if (option) {
                    handleNativeTool(option.key as number);
                  }
                }}
                options={nativeToolOption}
                defaultSelectedKey={nativeTool}
              />
              <TextField
                className={styles.textField}
                label="Version of Native tool "
                value={versionOfNativeTool || undefined}
                onChange={(e, newValue) =>
                  setVersionOfNativeTool(newValue === undefined ? null : newValue)
                }
              />
              <TextField
                className={styles.textField}
                label="Storage of Native file"
                value={storageOfNativeFile || undefined}
                onChange={(e, newValue) =>
                  setStorageOfNativeFile(newValue === undefined ? null : newValue)
                }
              />
              <TextField
                className={styles.textField}
                label="Component Catalog name"
                value={componentCatalogName || undefined}
                onChange={(e, newValue) =>
                  setComponentCatalogName(newValue === undefined ? null : newValue)
                }
              />
            </div>
            <div className={styles.fieldsContainer}>
              <Dropdown
                label="Design For Ex Areas"
                className={styles.dropdown}
                onChange={(e, option) => {
                  if (option) {
                    handleDesignForExAreas(option.key as number);
                  }
                }}
                options={designForExAreasOption}
                defaultSelectedKey={designForExAreas}
              />
              <Dropdown
                label="Halogen Free"
                className={styles.dropdown}
                onChange={(e, option) => {
                  if (option) {
                    handleHalogenFree(option.key as number);
                  }
                }}
                options={yesNoNaOption}
                defaultSelectedKey={halogenFree}
              />
              <Dropdown
                label="Redundancy"
                className={styles.dropdown}
                onChange={(e, option) => {
                  if (option) {
                    handleRedundancy(option.key as number);
                  }
                }}
                options={yesNoNaOption}
                defaultSelectedKey={redundancy}
              />
              <TextField
                className={styles.textField}
                label="Type"
                value={type || undefined}
                onChange={(e, newValue) => setType(newValue === undefined ? null : newValue)}
              />
            </div>
            <TextField
              className={styles.description}
              label="Description"
              multiline
              value={description || undefined}
              onChange={(e, newValue) => setDescription(newValue === undefined ? null : newValue)}
            />

            <div className={styles.fieldsContainer}>
              <TextField
                className={styles.textField}
                label="Plinth"
                value={plinth || undefined}
                onChange={(e, newValue) => setPlinth(newValue === undefined ? null : newValue)}
              />
              <TextField
                className={styles.textField}
                label="Height"
                value={height || undefined}
                onChange={(e, newValue) => setHeight(newValue === undefined ? null : newValue)}
              />
              <TextField
                className={styles.textField}
                label="Width"
                value={width || undefined}
                onChange={(e, newValue) => setWidth(newValue === undefined ? null : newValue)}
              />
              <TextField
                className={styles.textField}
                label="Depth"
                value={depth || undefined}
                onChange={(e, newValue) => setDepth(newValue === undefined ? null : newValue)}
              />
            </div>
            <div className={styles.fieldsContainer}>
              <TextField
                className={styles.textField}
                label="IP"
                value={ip || undefined}
                onChange={(e, newValue) => setIp(newValue === undefined ? null : newValue)}
              />
              <TextField
                className={styles.textField}
                label="Cabinet Access (doors)"
                value={cabinetAccess || undefined}
                onChange={(e, newValue) =>
                  setCabinetAccess(newValue === undefined ? null : newValue)
                }
              />
              <TextField
                className={styles.textField}
                label="IO Count"
                value={ioCount?.toString() || undefined}
                onChange={(e, newValue) => handleIOCount(newValue || null)}
                errorMessage={iOCountErrorMessage}
              />
              <TextField
                className={styles.textField}
                label="Number Of Controllers"
                value={numberOfControllers?.toString() || undefined}
                onChange={(e, newValue) => handleNumbersOfControllers(newValue || null)}
                errorMessage={numberOfControllersErrorMessage}
              />
            </div>
            <div className={styles.fieldsContainer}>
              <Dropdown
                label="Equipment On Door"
                className={styles.dropdown}
                onChange={(e, option) => {
                  if (option) {
                    handleEquipmentOnDoor(option.key as number);
                  }
                }}
                options={yesNoNaOption}
                defaultSelectedKey={equipmentOnDoor}
              />
              <TextField
                className={styles.textField}
                label="Cable Entry"
                value={cableEntry || undefined}
                onChange={(e, newValue) => setCableEntry(newValue === undefined ? null : newValue)}
              />
              <TextField
                className={styles.textField}
                label="Power Supply"
                value={powerSupply || undefined}
                onChange={(e, newValue) => setPowerSupply(newValue === undefined ? null : newValue)}
              />
              <TextField
                className={styles.textField}
                label="Power Supply Producer"
                value={powerSupplyProducer || undefined}
                onChange={(e, newValue) =>
                  setPowerSupplyProducer(newValue === undefined ? null : newValue)
                }
              />
            </div>
            <div className={styles.fieldsContainer}>
              <TextField
                className={styles.textField}
                label="Cabinet Catalog No"
                value={cabinetCatalogNo || undefined}
                onChange={(e, newValue) =>
                  setCabinetCatalogNo(newValue === undefined ? null : newValue)
                }
              />
              <TextField
                className={styles.textField}
                label="Manufactured By"
                value={manufacturedBy || undefined}
                onChange={(e, newValue) =>
                  setManufacturedBy(newValue === undefined ? null : newValue)
                }
              />
              <TextField
                className={styles.textField}
                label="Cabinet Frame"
                value={cabinetFrame || undefined}
                onChange={(e, newValue) =>
                  setCabinetFrame(newValue === undefined ? null : newValue)
                }
              />
              <TextField
                className={styles.textField}
                label="Control System"
                value={controlSystem || undefined}
                onChange={(e, newValue) =>
                  setControlSystem(newValue === undefined ? null : newValue)
                }
              />
            </div>
            <div className={styles.fieldsContainer}>
              <Dropdown
                label="Layout"
                className={styles.dropdown}
                onChange={(e, option) => {
                  if (option) {
                    handleLayout(option.key as number);
                  }
                }}
                options={yesNoNaOption}
                defaultSelectedKey={layout}
              />
              <Dropdown
                label="Wiring"
                className={styles.dropdown}
                onChange={(e, option) => {
                  if (option) {
                    handleWiring(option.key as number);
                  }
                }}
                options={yesNoNaOption}
                defaultSelectedKey={wiring}
              />
              <Dropdown
                label="Fuse List"
                className={styles.dropdown}
                onChange={(e, option) => {
                  if (option) {
                    handleFuseList(option.key as number);
                  }
                }}
                options={yesNoNaOption}
                defaultSelectedKey={fuseList}
              />
              <Dropdown
                label="BOM"
                className={styles.dropdown}
                onChange={(e, option) => {
                  if (option) {
                    handleBOM(option.key as number);
                  }
                }}
                options={yesNoNaOption}
                defaultSelectedKey={bom}
              />
            </div>
            <footer className={styles.buttons}>
              <TheButton disabled={isLoading} onClick={onCloseModal}>Close</TheButton>
              <TheButton  primary disabled={isLoading || errorExists} onClick={onSubmit}>
                Update
              </TheButton>
            </footer>
          </>
        )}
      </div>
    </>
  );
};
