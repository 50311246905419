import styles from './Unit.module.scss';
import {actions, PermissionToCheck} from '../../../enums';
import {ApiResponse} from '../../../services/ApiResponseType';
import {checkPermissions} from '../../../helpers/permissionsHtml';
import {DropdownMenu} from '../../common/DropdownMenu';
import {MiddleTopBar} from '../../common/MiddleTopBar/MiddleTopBar';
import {NavLink, useLocation, useNavigate} from 'react-router-dom';
import {ProcessTree, RolePermission} from '../../../models';
import {TheButton} from '../../common/TheButton';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useUnit} from './useUnit';
import {InfoTooltip} from '../../common/InfoTooltip';
import { useAdmin } from '../../../hooks/services/useAdmin';

type IMiddleTopBar = {
  publishedData: ProcessTree[] | undefined | null;
  unitId: number | undefined;
  unitPermissions?: ApiResponse<RolePermission[]> | undefined;
  showManage?: boolean;
  farItemsOverride?: JSX.Element;
};

export const MiddleTopBarUnit = ({
  publishedData,
  unitId,
  unitPermissions,
  showManage,
  farItemsOverride
}: IMiddleTopBar) => {
  const fullUrl = useLocation(); 
  const myProcessList = fullUrl.pathname.indexOf("myProcessList") !== -1;
  const processMap = fullUrl.pathname.indexOf("processMap") !== -1;
  const cabinetTypicalsList = fullUrl.pathname.indexOf("cabinetTypicalsList") !== -1;
  const navigate = useNavigate();
  const onClickGoToManage = useCallback(() => {
    const fullUrl = `/unit/${unitId}/manage`;
    navigate(fullUrl, {replace: true});
  }, [unitId, navigate]);
  const {getGlobalSettings} = useAdmin();
  const {unit} = useUnit(unitId);
  const [cabinetTypicalsFeatureEnabled, setCabinetTypicalsFeatureEnabled] = useState<boolean>(false);

 useEffect(() => {
  const fetchSettings = async () => {
    const settingsResponse = await getGlobalSettings();
    if (settingsResponse?.result) {
      const cabinetTypicalsFeatureEnabled = settingsResponse.result.find(
        x => x.name === 'cabinetTypicals'
      );
      if (cabinetTypicalsFeatureEnabled) {
        setCabinetTypicalsFeatureEnabled(cabinetTypicalsFeatureEnabled.value === 'true');
      }
    }
  };
  fetchSettings();
}, [getGlobalSettings]);


  return (
    <MiddleTopBar
      unitId={unitId}
      hideFilters={myProcessList || processMap || cabinetTypicalsList}
      farItems={
        farItemsOverride || (
          <div className={styles.farButtonsWrapper}>
            {showManage &&
            unitId &&
            checkPermissions(
              PermissionToCheck.UnitManageButton,
              actions.UPDATE,
              unitPermissions?.result
            ) ? (
              <div className={styles.manageButton}>
                <TheButton onClick={onClickGoToManage}>Manage</TheButton>
                <InfoTooltip content="Configure your unit" />
              </div>
            ) : undefined}
          </div>
        )
      }>
      <>
        {publishedData && publishedData.length > 0 && (
          <li>
            <DropdownMenu header={'Process'} menuItems={publishedData} unitId={unitId} />
          </li>
        )}
       
          <li>
            <NavLink to={`/unit/${unitId}/processList`}>Process List</NavLink>
          </li>
        <li>
          <NavLink to={`/unit/${unitId}/myProcessList`}>My Process List</NavLink>
        </li>
        <li>
          <NavLink to={`/unit/${unitId}/myAcknowledgements`}>My Acknowledgements</NavLink>
        </li>
        <li>
          <NavLink to={`/unit/${unitId}/documentsList`}>Documents List</NavLink>
        </li>
        {cabinetTypicalsFeatureEnabled && (
        <li>
          <NavLink to={`/unit/${unitId}/cabinetTypicalsList`}>Cabinet Typicals List</NavLink>
        </li>
        )}
        <li>
          <NavLink to={`/unit/${unitId}/processMap`}>Process Map</NavLink>
        </li>
        {unit && unit.pdcLink && (
          <li>
            <a
              href={unit.pdcLink.includes('http') ? unit.pdcLink : 'https://' + unit.pdcLink}
              rel="noreferrer"
              target="_blank">
              PDC Documents Library
            </a>
          </li>
        
        )}
        
      </>
    </MiddleTopBar>
  );
};
