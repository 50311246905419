import {
  ActivityDto,
  DocumentDto,
  ExternalLinkDto,
  ManageProcessDetailsDto
} from '../services/generated';
import {ProcessVersionFlatStructure} from './ProcessVersionFlatStructure';

export class ManageProcessDetails
  extends ProcessVersionFlatStructure
  implements ManageProcessDetailsDto
{
  draftExists: boolean;
  externalLinks: ExternalLinkDto[] | null;
  activityInputs: ActivityDto[] | null;
  activityOutputs: ActivityDto[] | null;
  documents: DocumentDto[] | null;
  nextUpdate: string;
  isMirrorCopy: boolean;
  mirrorCopyEnabled: boolean;

  constructor(processDetails: ManageProcessDetailsDto) {
    super(processDetails);
    this.draftExists = processDetails.draftExists;
    this.externalLinks = processDetails.externalLinks;
    this.activityInputs = processDetails.activityInputs;
    this.activityOutputs = processDetails.activityOutputs;
    this.documents = processDetails.documents;
    this.nextUpdate = processDetails.nextUpdate;
    this.isMirrorCopy = processDetails.isMirrorCopy;
    this.mirrorCopyEnabled = processDetails.mirrorCopyEnabled;
  }
}
