/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type {CancelablePromise} from '../core/CancelablePromise';
import {OpenAPI} from '../core/OpenAPI';
import {request as __request} from '../core/request';
import { CabinetTypicalDocumentDto } from '../models/CabinetTypicalDocumentDto';
import { CabinetTypicalDto } from '../models/CabinetTypicalDto';
import { EditCabinetTypicalDto } from '../models/EditCabinetTypicalDto';
import { UserDto } from '../models/UserDto';

export class CabinetTypicalService {
  /**
   * @returns Array<CabinetTypicalDto> Success
   * @throws ApiError
   */
  public static getCabinetTypicals(): CancelablePromise<Array<CabinetTypicalDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/cabinetTypical',
    });
  }

  public static getCabinetTypicalRelatedDocuments(id: number): CancelablePromise<Array<CabinetTypicalDocumentDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: `/api/cabinetTypical/${id}/documents`,
    });
  }

  public static getCabinetTypicalForEdit(id: number): CancelablePromise<EditCabinetTypicalDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: `/api/cabinetTypical/${id}/edit`,
    });
  }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static putCabinetTypical(id: number,
      requestBody?: EditCabinetTypicalDto
    ): CancelablePromise<any> {
      return __request(OpenAPI, {
        method: 'PUT',
        url: '/api/cabinetTypical/{id}/edit',
        body: requestBody,
        path: {
          id: id
        },
        mediaType: 'application/json'
      });
    }

     /**
       * @returns UserDto Success
       * @throws ApiError
       */
      public static getApiCabinetTypicalsEditors(): CancelablePromise<Array<UserDto>> {
        return __request(OpenAPI, {
          method: 'GET',
          url: '/api/cabinetTypical/editors',
        });
      }


       /**
   * @param requestBody
   * @returns any Success
   * @throws ApiError
   */
  public static putApiCabinetTypicalsEditors(requestBody?: Array<string>): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/cabinetTypical/editors',
      body: requestBody,
      mediaType: 'application/json'
    });
  }


}
