import {
  Dropdown,
  IDropdown,
  IDropdownOption,
  IDropdownProps,
  IRenderFunction,
  ISelectableDroppableTextProps,
  SearchBox
} from '@fluentui/react';
import {FC, useCallback, useState} from 'react';
import styles from './DropdownWithSearch.module.scss';
import React from 'react';

interface ICustomProps extends IDropdownProps {
  removedKeys?: number[];

}

export const DropdownWithSearch: FC<ICustomProps> = props => {
  const [filteredOptions, setFilteredOptions] = useState<IDropdownOption[]>(props.options);
  const onSearchChange = useCallback(
    (event?: React.ChangeEvent<HTMLInputElement> | undefined, newValue?: string | undefined) => {
      
      if(props.multiSelect){
        setFilteredOptions(props.options.filter(
          item => !props.removedKeys?.some(x => x === item.key) && (!newValue || !item.text || props.defaultSelectedKeys?.some(x => x === item.key)  ||  item.text?.toLocaleLowerCase().includes(newValue.toLocaleLowerCase()))));
      }

      else
      {
        setFilteredOptions(props.options.filter(
        item => !newValue || !item.text || item.key === props.defaultSelectedKey || item.text?.toLocaleLowerCase().includes(newValue.toLocaleLowerCase())));

      }
    },
    [props]
  );

  const resetQuery = useCallback(() => {
    setFilteredOptions(props.options);
}, [props.options]);

  const onRenderList: IRenderFunction<ISelectableDroppableTextProps<IDropdown, HTMLDivElement>> =
    useCallback(
      (props, defaultRender) =>
        defaultRender && props ? (
          <>
            <SearchBox
              placeholder="Filter options"
              onChange={onSearchChange}
              className={styles.search}
            />
            {defaultRender({
              ...props,
              options: filteredOptions,

            })}
          </>
        ) : null,
        
      [filteredOptions, onSearchChange]
    );


 

  return <Dropdown {...props} options={filteredOptions} onRenderList={onRenderList} onFocus={resetQuery} />;
};
