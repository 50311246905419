import {ComboBox, Dropdown, IDropdownOption, Spinner} from '@fluentui/react';
import {FC, useMemo, useState} from 'react';
import {NavLink} from 'react-router-dom';
import {ProcessType} from '../../../enums';
import {MinimalProcessForDiagramDto} from '../../../services/generated';
import {TheButton} from '../TheButton';
import {MatrixComboBox} from './MatrixComboBox';
import styles from './MatrixDiagram.module.scss';
import {IProcessOption} from './useDiagram';
import {useMatrixDiagram} from './useMatrixDiagram';
import {DropdownWithSearch} from '../DropdownWithSearch';
import DoubleScrollbar from 'react-double-scrollbar';
import {on} from 'events';

export interface IMatrixValue {
  row: number;
  column: number;
  processes: MinimalProcessForDiagramDto[];
  customValue: string | null;
}

export interface IMatrixDiagramProps {
  isEdit: boolean;
  isProcessesLoading: boolean;
  processesOptions: IProcessOption[];
  processesOptionsFiltered: IProcessOption[];
  processTitle: string;
  unitId: number;
  matrixValues: IMatrixValue[];
  setMatrixValues: (matrixValues: IMatrixValue[]) => void;
}

export const MatrixDiagram: FC<IMatrixDiagramProps> = ({
  isEdit,
  isProcessesLoading,
  processesOptions,
  processesOptionsFiltered,
  processTitle,
  unitId,
  matrixValues,
  setMatrixValues
}) => {
  const {
    colsCount,
    rowsCount,
    incrementCols,
    incrementRows,
    onProcessSelect,
    removeRow,
    removeCol
  } = useMatrixDiagram(matrixValues, setMatrixValues);

  const [valueChange, setValueChange] = useState(false);

  const onOptionChanged = (
    option: IDropdownOption,
    defaultValuesKeys: number[],
    removedKeys: number[]
  ) => {
    var removedValue = removedKeys.find(val => val === option.key);
    var value = defaultValuesKeys.find(val => val === option.key);
    if (option.selected) {
      if (!value) {
        defaultValuesKeys.push(option.key as number);
      }

      if (!removedValue) {
        removedKeys = removedKeys.filter(val => val !== option.key);
      }
      
    } else if (!removedValue) {
      removedKeys.push(option.key as number);
    }
    defaultValuesKeys = defaultValuesKeys.filter(item => !new Set(removedKeys).has(item));
  };

  const ProcessPicker = useMemo(
    () =>
      ({
        multiSelect,
        rowIndex,
        colIndex
      }: {
        rowIndex: number;
        colIndex: number;
        multiSelect?: boolean;
      }) => {
        const currentMatrixValue = matrixValues.find(
          value => value.column === colIndex && value.row === rowIndex
        );
        const defaultValues = currentMatrixValue?.processes;
        let defaultValuesKeys = defaultValues?.map(val => val.id) || [];
        let removedKeys: number[] = [];

        return (
          <>
            {isEdit && colIndex === 0 ? (
              <TheButton
                className={styles.deleteButton}
                iconProps={{iconName: 'Trash'}}
                onClick={() => removeRow(rowIndex)}>
                Delete row
              </TheButton>
            ) : null}
            {isEdit && rowIndex === 0 ? (
              <TheButton
                className={styles.deleteButton}
                iconProps={{iconName: 'Trash'}}
                onClick={() => removeCol(colIndex)}>
                Delete column
              </TheButton>
            ) : null}
            {isProcessesLoading ? (
              <Spinner />
            ) : isEdit ? (
              colIndex === 0 ? (
                <MatrixComboBox
                  processesOptions={processesOptions}
                  defaultSelectedKey={
                    currentMatrixValue?.customValue || currentMatrixValue?.processes[0]?.id
                  }
                  onChanged={option => {
                    if (option) onProcessSelect(rowIndex, colIndex, option);
                  }}
                />
              ) : (
                <DropdownWithSearch
                  defaultSelectedKey={
                    !multiSelect && defaultValuesKeys ? defaultValuesKeys[0] : undefined
                  }
                  defaultSelectedKeys={multiSelect ? defaultValuesKeys : undefined}
                  options={processesOptions}
                  removedKeys={removedKeys}
                  multiSelect={multiSelect}
                  onDismiss={() => setValueChange(!valueChange)}
                  onChange={(e, option) => {
                    if (option) {
                      if (multiSelect) {
                        onOptionChanged(option, defaultValuesKeys, removedKeys);
                      }
                      onProcessSelect(rowIndex, colIndex, option, multiSelect);
                    }
                  }}
                />
              )
            ) : currentMatrixValue?.customValue ? (
              <div key={currentMatrixValue?.customValue} className={styles.nonlink}>
                {currentMatrixValue?.customValue}
              </div>
            ) : (
              <>
                {defaultValues?.map(value => {
                  const key = value.id;
                  if (value.deleted) {
                    return (
                      <div key={key} className={`${styles.nonlink} ${styles.deleted}`}>
                        {value.title}
                      </div>
                    );
                  }
                  const process = processesOptionsFiltered.find(item => item.key === key);
                  if (!process) return null;
                  const isProcess = process.data.type === ProcessType.Process;
                  return isProcess ? (
                    <NavLink
                      key={key}
                      className={styles.link}
                      to={`/unit/${unitId}/process/${process.key}`}>
                      {value.title}
                    </NavLink>
                  ) : (
                    <div key={key} className={styles.nonlink}>
                      {value.title}
                    </div>
                  );
                })}
              </>
            )}
          </>
        );
      },
    [
      isEdit,
      isProcessesLoading,
      processesOptions,
      processesOptionsFiltered,
      matrixValues,
      unitId,
      onProcessSelect,
      removeCol,
      removeRow,
      valueChange
    ]
  );
  const tdStyles = useMemo(() => ({maxWidth: `calc(100vw / ${colsCount + 2})`}), [colsCount]);
  return (
    <div className={`${styles.tableWrapper}${isEdit ? ` ${styles.isEdit}` : ''}`}>
      <DoubleScrollbar>
        <table className={styles.matrixDiagramFront}>
          <thead>
            <tr>
              <td style={tdStyles}>{processTitle}</td>
              {Array.from(Array(colsCount), (e, colIndex) => (
                <td key={colIndex} style={tdStyles}>
                  <ProcessPicker rowIndex={0} colIndex={colIndex + 1} />
                </td>
              ))}
              {isEdit ? (
                <td>
                  <TheButton iconProps={{iconName: 'Add'}} onClick={incrementCols}>
                    Add column
                  </TheButton>
                </td>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {Array.from(Array(rowsCount), (e, rowIndex) => (
              <tr key={rowIndex}>
                <td style={tdStyles}>
                  <ProcessPicker colIndex={0} rowIndex={rowIndex + 1} />
                </td>
                {Array.from(Array(colsCount), (e, colIndex) => (
                  <td key={colIndex} style={tdStyles}>
                    <ProcessPicker colIndex={colIndex + 1} rowIndex={rowIndex + 1} multiSelect />
                  </td>
                ))}
              </tr>
            ))}
            {isEdit ? (
              <tr>
                <td colSpan={colsCount + 2}>
                  <TheButton iconProps={{iconName: 'Add'}} onClick={incrementRows}>
                    Add row
                  </TheButton>
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </DoubleScrollbar>
    </div>
  );
};
