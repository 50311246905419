import styles from './Navigation.module.scss';
import {Icon, TooltipHost} from '@fluentui/react';
import {NavLink} from 'react-router-dom';
import {useNavigation} from './useNavigation';
import {useContext, useMemo, useState} from 'react';
import {GlobalSettingsContext} from '../../../providers/GlobalSettingsContext';
import {ChatBotModal} from '../../Pages/ChatBot';

export const Navigation = () => {
  const {isUserAdmin, isHomeUnitCurrent, isHomeUnitSelected, favUnitUrl, logout} = useNavigation();
  const {globalSettings} = useContext(GlobalSettingsContext);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const unitId = useMemo(() => {
    return globalSettings.unitId;
  }, [globalSettings]);

  const chatbotFlag = useMemo(() => {
    return globalSettings.settings.chatbot === 'true';
  }, [globalSettings]);

  const contactUs = (): string => {
    if (!globalSettings.settings.contactUs) {
      return '';
    }
    if (!globalSettings.settings.contactUs.startsWith('http')) {
      return `https://${globalSettings.settings.contactUs}`;
    }

    return globalSettings.settings.contactUs;
  };

  return (
    <div className={styles.navigation}>
      {unitId && chatbotFlag ? (
        <TooltipHost content={'Chatbot'}>
          <div className={styles.chatbotContainer}>
            <div className={styles.chatbotIcon}>
              <Icon
                iconName="ChatBot"
                onClick={() => {
                  setIsOpenModal(true);
                }}
              />
            </div>
          </div>
        </TooltipHost>
      ) : null}
      {contactUs() ? (
        <TooltipHost content={'Contact us'}>
          <a href={contactUs()} target="_blank" rel="noreferrer">
            <Icon iconName="Headset" />
          </a>
        </TooltipHost>
      ) : null}
      <TooltipHost content={'Help/Manual'}>
        <a
          href={'https://pdc.abb.com/sites/ims/Help/Forms/AllItems.aspx'}
          target="_blank"
          rel="noreferrer">
          <Icon iconName="Help" />
        </a>
      </TooltipHost>
      {isUserAdmin ? (
        <TooltipHost content={'Admin Panel'}>
          <NavLink to={'/admin'} className={({isActive}) => (isActive ? styles.active : '')}>
            <Icon iconName="Admin" />
          </NavLink>
        </TooltipHost>
      ) : null}
      {isHomeUnitSelected ? (
        !isHomeUnitCurrent ? (
          <TooltipHost content={'Go to my home unit'}>
            <NavLink to={favUnitUrl} className={({isActive}) => (isActive ? styles.active : '')}>
              <Icon iconName="Pinned" style={{color: 'red'}} />
            </NavLink>
          </TooltipHost>
        ) : (
          <TooltipHost content={'My home unit'}>
            <NavLink
              to={favUnitUrl}
              className={({isActive}) => `${styles.myUnit} ${isActive ? styles.active : ''}`}>
              <Icon iconName="PinnedSolid" />
            </NavLink>
          </TooltipHost>
        )
      ) : (
        <></>
      )}
      <TooltipHost content={'Logout'}>
        <NavLink to={'#'} onClick={logout}>
          <Icon iconName="Leave" />
        </NavLink>
      </TooltipHost>

      <ChatBotModal isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} unitId={unitId} />
    </div>
  );
};
