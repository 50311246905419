import {FC, useEffect, useState} from 'react';
import {ModalWithHeader} from '../../common/ModalWithHeader';
import {useUser} from '../../../hooks/services/useUser';

interface IChatBotModal {
  isOpenModal: boolean;
  setIsOpenModal: (isOpen: boolean) => void;
  unitId: string | null;
}

export const ChatBotModal: FC<IChatBotModal> = ({isOpenModal, setIsOpenModal, unitId}) => {
  const {getCurrentUser} = useUser();
  const [sid, setSid] = useState<string>('');

  useEffect(() => {
    const checkAuthentication = async () => {
      if (isOpenModal === false) return;
      try {
        const user = await getCurrentUser();
        const sid = user?.idTokenClaims?.sid ?? '';
        setSid(sid);

        const tokenExpirationDate = user?.idTokenClaims?.exp;
        const now = new Date().getTime();

        if (tokenExpirationDate === undefined || tokenExpirationDate * 1000 < now) {
          window.location.reload();
          setIsOpenModal(false);
        }
      } catch (error) {
        window.location.reload();
        setIsOpenModal(false);
      }
    };
    checkAuthentication();
    const interval = setInterval(checkAuthentication, 300000); // 5 minutes

    return () => clearInterval(interval);
  }, [setIsOpenModal, isOpenModal, getCurrentUser]);

  return (
    <ModalWithHeader
      isBlocking={true}
      header="Chatbot"
      isVisible={isOpenModal}
      dismiss={() => setIsOpenModal(false)}>
      <>
        <iframe
          allow="clipboard-write"
          key="chatbot"
          style={{width: '100%', height: '85vh', border: 'none'}}
          title="Chatbot"
          src={`${process.env.REACT_APP_CHATBOT_URL}?instance=${process.env.REACT_APP_DIVISION_NAME}&unitnumber=${unitId}&sid=${sid}`}></iframe>
      </>
    </ModalWithHeader>
  );
};
