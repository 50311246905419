import {
  EnhancedDetailsList,
  OtherColumn,
  SelectColumn
} from '../../common/EnhancedDetailsList';
import {
  ColumnKey,
  IEnhancedColumn,
  SpecialColumnKey
} from '../../common/EnhancedDetailsList/IEnhancedDetailsList';
import {HeadingWithDecoration} from '../../common/HeadingWithDecoration';
import {Label, Shimmer, Spinner} from '@fluentui/react';
import {MiddleTopBarUnit} from '../Unit/MiddleTopBarUnit';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {Helmet} from 'react-helmet';
import * as useUnitFromUnit from '../Unit/useUnit';
import {CabinetTypical} from '../../../models/CabinetTypical';
import {useCabinetTypicals} from '../../../hooks/services/useCabinetTypicals';
import {OpenPDCCabinetColumn} from '../../common/EnhancedDetailsList/ColumnTypes/OpenPDCCabinetColumn';
import {TheButton} from '../../common/TheButton';
import {ModalWithHeader} from '../../common/ModalWithHeader';
import {EditCabinetTypical} from './EditCabinetTypical';
import {useService} from '../../../hooks';
import {checkPermissions} from '../../../helpers/permissionsHtml';
import {actions, PermissionToCheck} from '../../../enums';
import {InfoTooltip} from '../../common/InfoTooltip';
import styles from './CabinetTypicals.module.scss';
import {PeoplePicker} from '../../common/PeoplePicker';
import {useCabinetTypical} from './useCabinetTypical';

export const CabinetTypicals: React.FunctionComponent = () => {
  const {unitId, apiMsg, publishedProcessesForUnit, unitPermissions} = useUnitFromUnit.useUnit();

  const {
    isLoadingInProgress,
    isUserAdmin,
    manageCabTypRolesModal,
    onManageCabTypRoles,
    setManageCabTypRolesModal,
    cabinetEditorsSelect,
    onSelectCabinetEdtitors,
    saveForm
  } = useCabinetTypical();

  const {getCabinetTypicals} = useCabinetTypicals();
  const {getUserPermissionsPage} = useService();


  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [cabinetTypicals, setCabinetTypicals] = useState<CabinetTypical[]>([]);
  const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);
  const [editedId, setEditedId] = useState<number>(0);
  const [title, setTitle] = useState<string | null>(null);
  const [canEditCabinets, setCanEditCabinets] = useState<boolean>(false);
 

  const onEditDetailsClick = useCallback((item: CabinetTypical) => {
    setEditedId(item.id);
    setTitle(item.typicalName);
    setIsEditModalVisible(true);
  }, []);

  const refreshDocuments = useMemo(
    () => async () => {
      setIsLoading(true);
      const cabinetTypicalsResponse = await getCabinetTypicals();
      if (cabinetTypicalsResponse.result) {
        setCabinetTypicals(cabinetTypicalsResponse.result);
      }
      setIsLoading(false);
    },
    [getCabinetTypicals, setCabinetTypicals]
  );

  const onCloseModal = () => {
    setIsEditModalVisible(false);
  };

  const onCloseManageRoleModal = () => {
    setManageCabTypRolesModal(false);
  };

  useEffect(() => {
    (async () => {
      const response = await getUserPermissionsPage(unitId);
      if (response?.result) {
        const canEdit = checkPermissions(
          PermissionToCheck.EditCabinetTypicals,
          actions.UPDATE,
          response.result
        );
        setCanEditCabinets(canEdit);
      }
    })();
  }, [getUserPermissionsPage, setCanEditCabinets, unitId]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      refreshDocuments();
      setIsLoading(false);
    })();
  }, [getCabinetTypicals, refreshDocuments]);

  const isSmart = useMemo(
    () => typeof new URLSearchParams(document.location.search).get('smart') === 'string',
    []
  );
  const columns = useMemo(() => {
    const cols: IEnhancedColumn<CabinetTypical>[] = [
      {
        key: ColumnKey.Url,
        name: '',
        data: OpenPDCCabinetColumn
      },
      ...(isSmart
        ? ([
            {
              key: 'id',
              name: 'ID',
              minWidth: 130
            }
          ] as IEnhancedColumn<CabinetTypical>[])
        : []),
      {
        key: ColumnKey.TypicalName,
        name: 'Typical Name',
        minWidth: 330,
        flexGrow: 1,
        exportToExcel: true,
        onExportToExcel: (item, column) => {
          return {
            text: item.typicalName,
            link: item.url
          };
        }
      },
      ...(canEditCabinets
        ? ([
            {
              key: SpecialColumnKey.Edit,
              name: 'Edit details',
              onRender: (item: CabinetTypical) => {
                return (
                  <TheButton
                    iconProps={{
                      iconName: 'Edit'
                    }}
                    onClick={() => onEditDetailsClick(item)}
                    disabled={false}
                    primary>
                    Edit details
                  </TheButton>
                );
              },
              data: OtherColumn,
              minWidth: 200
            }
          ] as IEnhancedColumn<CabinetTypical>[])
        : []),
      {
        key: ColumnKey.NativeToolString,
        name: 'Native Tool',
        data: SelectColumn,
        minWidth: 135,
        exportToExcel: true
      },
      {
        key: ColumnKey.ComponentCatalogName,
        name: 'Component Catalog Name',
        minWidth: 235,
        exportToExcel: true
      },
      {
        key: ColumnKey.DesingForExAreasString,
        name: 'Design For Ex Areas',
        data: SelectColumn,
        minWidth: 200,
        exportToExcel: true
      },
      {
        key: ColumnKey.HalogenFreeString,
        name: 'Halogen Free',
        data: SelectColumn,
        minWidth: 150,
        exportToExcel: true
      },
      {
        key: ColumnKey.RedundancyString,
        name: 'Redundancy',
        data: SelectColumn,
        minWidth: 135,
        exportToExcel: true
      },
      {
        key: ColumnKey.Description,
        name: 'Description',
        minWidth: 330,
        exportToExcel: true
      },
      {
        key: ColumnKey.Type,
        name: 'Type',
        data: SelectColumn,
        minWidth: 135,
        exportToExcel: true
      },
      {
        key: ColumnKey.Plinth,
        name: 'Plinth',
        minWidth: 105,
        exportToExcel: true
      },
      {
        key: ColumnKey.Height,
        name: 'Height',
        minWidth: 105,
        exportToExcel: true
      },
      {
        key: ColumnKey.Width,
        name: 'Width',
        minWidth: 105,
        exportToExcel: true
      },
      {
        key: ColumnKey.Depth,
        name: 'Depth',
        minWidth: 105,
        exportToExcel: true
      },
      {
        key: ColumnKey.Ip,
        name: 'IP',
        data: SelectColumn,
        minWidth: 105,
        exportToExcel: true
      },

      {
        key: ColumnKey.CabinetAccess,
        name: 'Cabinet Access (doors)',
        minWidth: 205,
        exportToExcel: true
      },

      {
        key: ColumnKey.IOcount,
        name: 'IO Count',
        minWidth: 105,
        exportToExcel: true
      },
      {
        key: ColumnKey.NumberOfControllers,
        name: 'Number Of Controllers',
        minWidth: 205,
        exportToExcel: true
      },
      {
        key: ColumnKey.EquipmentOnDoorString,
        name: 'Equipment On Door',
        data: SelectColumn,
        minWidth: 200,
        exportToExcel: true
      },
      {
        key: ColumnKey.CableEntry,
        name: 'Cable Entry',
        minWidth: 150,
        exportToExcel: true
      },
      {
        key: ColumnKey.PowerSupply,
        name: 'Power Supply',
        minWidth: 150,
        exportToExcel: true
      },
      {
        key: ColumnKey.PowerSupplyProducer,
        name: 'Power Supply Producer',
        minWidth: 200,
        exportToExcel: true
      },
      {
        key: ColumnKey.CabinetCatalogNo,
        name: 'Cabinet Catalog No',
        minWidth: 205,
        exportToExcel: true
      },
      {
        key: ColumnKey.ManufacturedBy,
        name: 'Manufactured By',
        minWidth: 215,
        exportToExcel: true
      },
      {
        key: ColumnKey.CabinetFrame,
        name: 'Cabinet Frame',
        minWidth: 200,
        exportToExcel: true
      },
      {
        key: ColumnKey.ControlSystem,
        name: 'Control System',
        minWidth: 200,
        exportToExcel: true
      },
      {
        key: ColumnKey.LayoutString,
        name: 'Layout',
        data: SelectColumn,
        minWidth: 110,
        exportToExcel: true
      },
      {
        key: ColumnKey.WiringString,
        name: 'Wiring',
        data: SelectColumn,
        minWidth: 110,
        exportToExcel: true
      },
      {
        key: ColumnKey.FuseListString,
        name: 'Fuse List',
        data: SelectColumn,
        minWidth: 110,
        exportToExcel: true
      },
      {
        key: ColumnKey.BomString,
        name: 'BOM',
        data: SelectColumn,
        minWidth: 110,
        exportToExcel: true
      }
    ];
    return cols;
  }, [isSmart, onEditDetailsClick, canEditCabinets]);

  return (
    <>
      <main className={`${isLoading ? 'isLoading' : ''} noPaddingTop`}>
        {
          <Helmet>
            <title>IMS Cabinet Typicals List</title>
          </Helmet>
        }
        {isLoading ? (
          <Spinner />
        ) : (
          !apiMsg && (
            <>
              {publishedProcessesForUnit ? (
                <MiddleTopBarUnit
                  unitId={unitId}
                  publishedData={publishedProcessesForUnit?.result}
                  unitPermissions={unitPermissions}
                  showManage
                />
              ) : (
                <Shimmer />
              )}
              {<HeadingWithDecoration text={'Cabinet Typicals List'} />}
              <EnhancedDetailsList<CabinetTypical, CabinetTypical, CabinetTypical>
                columns={columns}
                items={cabinetTypicals}
                itemsFiltered={false}
                pageSize={30}
                manageCabTypRoles={isUserAdmin}
                onManageCabinetTypicalRoles={onManageCabTypRoles}
                excelExport
                excelFileName={'Exported Cabinet Typicals List'}
              />
              {isEditModalVisible && (
                <ModalWithHeader
                  header={`Edit: ${title}` || 'Edit Cabinet Typical'}
                  isVisible={isEditModalVisible}
                  dismiss={onCloseModal}>
                  <EditCabinetTypical
                    cabinetTypicalId={editedId}
                    setIsEditModalVisible={setIsEditModalVisible}
                    refreshDocuments={refreshDocuments}
                  />
                </ModalWithHeader>
              )}
              {manageCabTypRolesModal && (
                <ModalWithHeader
                  header={'Edit Cabinet Typical Editor Roles'}
                  isVisible={manageCabTypRolesModal}
                  dismiss={onCloseManageRoleModal}>
                  {isLoadingInProgress ? (
                    <Spinner />
                  ) : (
                    <>
                      <div className={styles.labelWrapper}>
                        <Label className={styles.label} htmlFor={'people'}>
                          Cabinet Typicals Editors
                        </Label>
                        <InfoTooltip content="Cabinet Editors have full access to Edit Cabinet Typicals Properties" />
                      </div>
                      <PeoplePicker
                        key={'normal'}
                        itemLimit={30}
                        defaultSelectedPerson={cabinetEditorsSelect}
                        onSelectPerson={onSelectCabinetEdtitors}
                        showRemoved
                      />
                    </>
                  )}
                  <div className={styles.footer}>
                    <TheButton onClick={onCloseManageRoleModal}>Cancel</TheButton>
                    <TheButton disabled={isLoadingInProgress} primary onClick={saveForm}>
                      Save
                    </TheButton>
                  </div>
                </ModalWithHeader>
              )}
            </>
          )
        )}
      </main>
    </>
  );
};
