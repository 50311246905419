/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import {IDropdownOption} from '@fluentui/react';

/**
 *
 *
 * 0 = N/A
 *
 * 1 = Yes
 *
 * 2 = No
 */
export enum YesNoNaType {
  NA = 0,
  Yes = 1,
  No = 2
}

export const yesNoNaOption: IDropdownOption[] = [
  {key: 0, text: 'N/A'},
  {key: 1, text: YesNoNaType[1]},
  {key: 2, text: YesNoNaType[2]}
];
