/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import { IDropdownOption } from "@fluentui/react";

/**
 *
 *
 * 0 = IS
 *
 * 1 = NONIS
 */
export enum DesignForExAreasType {
  IS = 0,
  NONIS = 1,
}


export const designForExAreasOption: IDropdownOption[] = [
  {key: 0, text: DesignForExAreasType[0]},
  {key: 1, text: 'NON IS'},

];
