import {IPersonaProps} from '@fluentui/react';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useService} from '../../../hooks';

import {LogMessageType} from '../../../services/LogMessagesType';
import {useCabinetTypicals} from '../../../hooks/services/useCabinetTypicals';
import { UserContext } from '../../../providers';

export const useCabinetTypical = () => {
  const {getUserById} = useService();

  const {isUserAdmin} = useContext(UserContext);
  const {getCabinetTypicalsEditors, updateCabinetTypicalEditors} = useCabinetTypicals();
  const [isLoadingInProgress, setIsLoadingProgress] = useState<boolean>(true);
  const [cabinetTypicalEditors, setCabinetTypicalEditors] = useState<IPersonaProps[]>();
  const [manageCabTypRolesModal, setManageCabTypRolesModal] = useState<boolean>(false);

  const getUser = useCallback(
    async (userId: string): Promise<IPersonaProps | undefined> => {
      if (userId) {
        const user = await getUserById(userId);
        const userToReturn = user
          ? {
              id: user.id,
              text: user.displayName,
              secondaryText: user.mail,
              key: user.id
            }
          : undefined;
        return userToReturn;
      }
    },
    [getUserById]
  );

  const cabinetTypicalsEditors = useCallback(async () => {
    if(!isUserAdmin)
    {
      return;
    }
    setIsLoadingProgress(true);
    const cabinetTypicalsEditors = await getCabinetTypicalsEditors();
    if (cabinetTypicalsEditors?.result) {
      let cabTypEditors: IPersonaProps[] = [];
      await Promise.all(
        cabinetTypicalsEditors.result.map(async element => {
          const cabTypicalEditor = await getUser(element.azureId);
          if (cabTypicalEditor) {
            cabTypEditors.push(cabTypicalEditor);
          }
        })
      );
      setCabinetTypicalEditors(cabTypEditors);
     } else {
      setCabinetTypicalEditors(undefined);
    }
    setIsLoadingProgress(false);
  }, [getCabinetTypicalsEditors, getUser, isUserAdmin]);

  const updateCabTypEditors = useCallback(
    async (data: string[], log?: LogMessageType) => {
      await updateCabinetTypicalEditors(data, log);
      await cabinetTypicalsEditors();
    },
    [updateCabinetTypicalEditors, cabinetTypicalsEditors]
  );

  const onManageCabTypRoles = useCallback(async () => {
    setIsLoadingProgress(true);
    setManageCabTypRolesModal(true);
    await cabinetTypicalsEditors();
    setIsLoadingProgress(false);
  }, [cabinetTypicalsEditors]);

  useEffect(() => {
    (async () => {
      if (isUserAdmin) {
        await cabinetTypicalsEditors();
      }
    })();
    /* eslint-disable*/
  }, [isUserAdmin]);
  /* eslint-enable*/

  const [cabinetEditorsSelect, setCabinetEditorsSelect] = useState<IPersonaProps[] | undefined>(
    cabinetTypicalEditors
  );

  const onSelectCabinetEdtitors = useCallback((value: IPersonaProps[] | undefined) => {
    setCabinetEditorsSelect(value);
  }, []);

  useEffect(() => {
    if (cabinetEditorsSelect === undefined && manageCabTypRolesModal) {
        setCabinetEditorsSelect(cabinetTypicalEditors);
    }
  }, [cabinetTypicalEditors, cabinetEditorsSelect,manageCabTypRolesModal]);

  const saveForm = useCallback(async () => {
    let result: string[] = [];
    cabinetEditorsSelect?.forEach(x => x.id !== undefined && result.push(x.id));
    let modified: boolean = true;
    if (cabinetTypicalEditors?.length === result.length) {
      cabinetTypicalEditors.forEach(element => {
        modified = !result.some(x => element.id === x);
      });
    }
    if(modified)
    {
      setIsLoadingProgress(true);
      await updateCabTypEditors(result);

    }
    setIsLoadingProgress(false);
    setManageCabTypRolesModal(false);
  }, [cabinetTypicalEditors, cabinetEditorsSelect, updateCabTypEditors]);

  return useMemo(
    () => ({
      isLoadingInProgress,
      isUserAdmin,
      manageCabTypRolesModal,
      onManageCabTypRoles,
      setManageCabTypRolesModal,
      onSelectCabinetEdtitors,
      cabinetEditorsSelect,
      setCabinetEditorsSelect,
      saveForm
    }),
    [
      isLoadingInProgress,
      isUserAdmin,
      manageCabTypRolesModal,
      onManageCabTypRoles,
      onSelectCabinetEdtitors,
      cabinetEditorsSelect,
      setCabinetEditorsSelect,
      saveForm
    ]
  );
};
