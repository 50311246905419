import {Modal} from '@fluentui/react';
import {FC} from 'react';
import {TheButton} from '../TheButton';
import {IModalWithHeaderProps} from './IModalWithHeaderProps';

import styles from './ModalWithHeader.module.scss';

export const ModalWithHeader: FC<IModalWithHeaderProps> = ({
  children,
  isVisible,
  isBlocking,
  dismiss,
  header
}) => {

  return (
    <Modal
      isOpen={isVisible}
      onDismiss={dismiss}
      isBlocking={isBlocking}
      containerClassName={styles.container}>
      <div className={styles.header}>
        <h5 className={styles.heading}>{header || 'Add items'}</h5>
        <TheButton
          isIcon
          className={styles.close}
          iconProps={{iconName: 'Cancel'}}
          ariaLabel="Close popup modal"
          onClick={dismiss}
        />
      </div>
      <div className={styles.content}>{children}</div>
    </Modal>
  );
};
