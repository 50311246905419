// import {IColumn} from '@fluentui/react';

import {IColumn} from '@fluentui/react';
import {SplitButton} from '../../SplitButton';
import { ColumnType } from '../IEnhancedDetailsList';
import { EnhancedColumn } from './EnhancedColumn';

export class OpenPDCCabinetDocumentColumn extends EnhancedColumn {
  public static type = ColumnType.Open;
  public static canFilter = false;
  public static canSort = false;
  public static isResizable = false;
  public static minWidth = 112;
  public static maxWidth = 112;
  public static renderer(value: string, column: IColumn, item?: any): JSX.Element {
    const siteAbsoluteUrlRegex = value && value.match(/.+\/ims\/[^/]+\//);
    const siteAbsoluteUrl = siteAbsoluteUrlRegex ? siteAbsoluteUrlRegex[0] : undefined;
    console.log(siteAbsoluteUrl)
    
    const editInPdc = {
      key: 'EditinPDC',
      text: 'Edit in PDC',
      iconProps: {
        iconName: 'PageEdit'
      },
      href: `${siteAbsoluteUrl}Forms/AllItems.aspx?RootFolder=%2Fsites%2Fims%2FCabinet%20Typicals%2F${item.cabinetTypicalName.replace(' ', '%20')}&FilterField1=ID&FilterValue1=${item.pdcId}`,
      target: '_blank'
    };

    console.log(editInPdc)
    const extraMenuProps = [editInPdc]
     
        return value ? (<SplitButton url={value} documentId={item.documentId} languageCode={item.languageCode} extraMenuProps={extraMenuProps} />)
        : <></>;
  }
}
