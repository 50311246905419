import {
  BooleanColumn,
  DateTimeColumn,
  EnhancedDetailsList,
  ProcessesColumn,
  SelectColumn
} from '../../common/EnhancedDetailsList';
import {ColumnKey, IEnhancedColumn} from '../../common/EnhancedDetailsList/IEnhancedDetailsList';
import {DocumentInUnit, Unit} from '../../../models';
import {HeadingWithDecoration} from '../../common/HeadingWithDecoration';
import {Icon, Shimmer, Spinner, TooltipHost} from '@fluentui/react';
import {MiddleTopBarUnit} from '../Unit/MiddleTopBarUnit';
import {useContext, useEffect, useMemo, useState} from 'react';
import * as useUnit from '../../../hooks/services/useUnit';
import {MultiselectColumn} from '../../common/EnhancedDetailsList/ColumnTypes/MultiselectColumn';
import {DictionariesContext} from '../../../providers';
import {OpenPDCColumn} from '../../common/EnhancedDetailsList/ColumnTypes/OpenPDCColumn';
import {Helmet} from 'react-helmet';
import * as useUnitFromUnit from '../Unit/useUnit';
import {StringArrayColumn} from '../../common/EnhancedDetailsList/ColumnTypes/StringArrayColumn';

export const UnitFiles: React.FunctionComponent = () => {
  const {unitId, apiMsg, unit, publishedProcessesForUnit, unitPermissions} =
    useUnitFromUnit.useUnit();
  const {getUnit} = useUnit.useUnit();

  const {getUnitDocuments} = useUnit.useUnit();
  const [unitTitle, setUnitTitle] = useState<string>();
  const {globalFiltersFunction} = useContext(DictionariesContext);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [documents, setDocuments] = useState<DocumentInUnit[] | null>(null);

  const prepareUnitTitle = (unit: Unit): string => {
    if (unit.title) {
      return unit.parentTitle && unit.unitTypeId === 2
        ? `${unit.parentTitle} ${unit.title}`
        : unit.title;
    }
    return '';
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const simpleUnit = await getUnit(unitId);
      const documentsResponse = await getUnitDocuments(unitId);
      setDocuments(documentsResponse.result);
      setIsLoading(false);
      if (simpleUnit && simpleUnit.result) {
        setUnitTitle(prepareUnitTitle(simpleUnit.result));
      }
    })();
  }, [getUnitDocuments, unitId, unit, getUnit]);

  const [itemsFiltered, setItemsFiltered] = useState<boolean>(false);

  const filteredDocuments = useMemo((): DocumentInUnit[] => {
    var result = documents ? documents.filter(globalFiltersFunction) : [];
    if (documents && documents.length > 0 && result.length !== documents.length) {
      setItemsFiltered(true);
    } else {
      setItemsFiltered(false);
    }

    return result;
  }, [documents, globalFiltersFunction]);

  const isSmart = useMemo(
    () => typeof new URLSearchParams(document.location.search).get('smart') === 'string',
    []
  );
  const columns = useMemo(() => {
    const cols: IEnhancedColumn<DocumentInUnit>[] = [
      {
        key: ColumnKey.Url,
        name: '',
        data: OpenPDCColumn
      },
      ...(isSmart
        ? ([
            {
              key: 'id',
              name: 'ID',
              minWidth: 130
            }
          ] as IEnhancedColumn<DocumentInUnit>[])
        : []),
      {
        key: ColumnKey.Title,
        name: 'Title',
        minWidth: 330,
        flexGrow: 1,
        exportToExcel: true,
        onExportToExcel: (item, column) => {
          return {
            text: item.title,
            link: item.url
          };
        }
      },
      {
        key: ColumnKey.DocumentId,
        name: 'Document ID',
        minWidth: 135,
        exportToExcel: true,
        onRender(item) {
          return (
            <>
              {item.documentId}
              {item.inReview && (
                <TooltipHost content="New revision is pending">
                  <Icon styles={{root: {paddingLeft: '5px', color: 'red'}}} iconName="Sync" />
                </TooltipHost>
              )}
            </>
          );
        }
      },
      {
        key: ColumnKey.ContentTypeString,
        name: 'Content Type',
        data: SelectColumn,
        minWidth: 135,
        exportToExcel: true
      },
      {
        key: ColumnKey.Processes,
        name: 'Process(es)',
        data: ProcessesColumn,
        minWidth: 135,
        exportToExcel: true
      },
      {
        key: ColumnKey.DocumentKind,
        name: 'Document Kind',
        data: SelectColumn,
        minWidth: 150,
        exportToExcel: true
      },
      {
        key: ColumnKey.Revision,
        name: 'Revision',
        data: SelectColumn,
        minWidth: 110,
        exportToExcel: true
      },
      {
        key: ColumnKey.OperatingUnit,
        name: 'Operating Unit',
        data: SelectColumn,
        minWidth: 150,
        exportToExcel: true
      },
      {
        key: ColumnKey.FunctionString,
        name: 'Function',
        data: SelectColumn,
        minWidth: 105,
        exportToExcel: true
      },
      {
        key: 'businessLines',
        name: 'Business Line',
        data: MultiselectColumn,
        minWidth: 190,
        exportToExcel: true
      },

      {
        key: 'industries',
        name: 'Industry',
        data: MultiselectColumn,
        minWidth: 130,
        exportToExcel: true
      },
      {
        key: 'specialities',
        name: 'Specialty',
        data: MultiselectColumn,
        minWidth: 110,
        exportToExcel: true
      },
      {
        key: 'projectSizeCategories',
        name: 'Project Size Category',
        data: MultiselectColumn,
        minWidth: 190,
        exportToExcel: true
      },
      {
        key: ColumnKey.DocumentOwner,
        name: 'Document Owner',
        minWidth: 160,
        exportToExcel: true
      },
      {
        key: ColumnKey.ApprovedDate,
        name: 'Approved Date',
        minWidth: 145,
        data: DateTimeColumn,
        exportToExcel: true
      },
      {
        key: ColumnKey.Auditable,
        name: 'Auditable',
        data: BooleanColumn,
        minWidth: 110,
        exportToExcel: true
      },
      {
        key: ColumnKey.RelevantTo,
        name: 'Related To',
        minWidth: 135,
        exportToExcel: true
      },
      {
        key: ColumnKey.Language,
        name: 'Language',
        data: SelectColumn,
        minWidth: 110,
        exportToExcel: true
      },
      {
        key: ColumnKey.SecurityLevel,
        name: 'Security Level',
        data: SelectColumn,
        minWidth: 140,
        exportToExcel: true
      },
      {
        key: ColumnKey.KeyWords,
        exportToExcel: true,
        name: 'Keywords',
        minWidth: 190,
        data: StringArrayColumn
      }
    ];
    return cols;
  }, [isSmart]);

  return (
    <>
      <main className={`${isLoading ? 'isLoading' : ''} noPaddingTop`}>
        {unitTitle && (
          <Helmet>
            <title>IMS {unitTitle} Documents List</title>
          </Helmet>
        )}
        {isLoading ? (
          <Spinner />
        ) : (
          !apiMsg && (
            <>
              {publishedProcessesForUnit ? (
                <MiddleTopBarUnit
                  unitId={unitId}
                  publishedData={publishedProcessesForUnit?.result}
                  unitPermissions={unitPermissions}
                  showManage
                />
              ) : (
                <Shimmer />
              )}
              {unit && <HeadingWithDecoration text={unit.title || ''} />}
              <EnhancedDetailsList<DocumentInUnit, DocumentInUnit, DocumentInUnit>
                columns={columns}
                items={filteredDocuments}
                itemsFiltered={itemsFiltered}
                pageSize={30}
                excelExport
                frozen
                excelFileName={'Exported Documents List'}
              />
            </>
          )
        )}
      </main>
    </>
  );
};
