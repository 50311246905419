import {useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {useService} from '../../../hooks';
import {
  DocumentRecent,
  ProcessFlatStructure,
  ProcessTree,
  RolePermission,
  Unit,
  UnitProcessNavigator
} from '../../../models';
import {AuthenticationContext} from '../../../providers/AuthenticationContext';
import {ApiResponse, ApiResponsePending} from '../../../services/ApiResponseType';
import {checkPermissions} from '../../../helpers/permissionsHtml';
import {PermissionToCheck, actions} from '../../../enums';
import {UnitStatus} from '../../../services/generated';
import {PreviousUnitPageContext} from '../../../providers/PreviousUnitPageContext';

export const useUnitGeneric = (getUnitFn: (unitId: number) => ApiResponsePending<Unit>, passedUnitId?: number,) => {
  const {unitId: unitIdStr} = useParams();
  const unitId = passedUnitId || Number(unitIdStr);
  const {isLoading: isAzureLoading} = useContext(AuthenticationContext);
  const {unitId: contextUnitId, setUnitId: setContextUnitId} = useContext(PreviousUnitPageContext);
  const {
    getUnitProcessNavigator,
    getDocumentsRecentlyAdded,
    getMenuProcessesPublishedForUnit,
    getUserPermissionsPage,
    getProcessesPublishedForUnitFlatForManage
  } = useService();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [processNavigatorData, setProcessNavigatorData] = useState<UnitProcessNavigator[]>();
  const [unit, setUnit] = useState<Unit | null>();
  const [publishedProcessesForUnit, setPublishedProcessesForUnit] =
    useState<ApiResponse<ProcessTree[]>>();

  const [itemsFlat, setItemsFlat] = useState<ApiResponse<ProcessFlatStructure[]>>();

  const [recentlyAddedDocument, setRecentlyAddedDocument] = useState<DocumentRecent[]>([]);
  const [apiMsg, setApiMsg] = useState<string | null>();
  const [unitPermissions, setUnitPermissions] = useState<ApiResponse<RolePermission[]>>();
  useEffect(() => {
    (async () => {
      if (!isAzureLoading) {
        setIsLoading(true);
        const unit = await getUnitFn(unitId);
        const permissions = await getUserPermissionsPage(unitId);

        if (
          unit &&
          unit.result &&
          unit.result.status === UnitStatus.Draft &&
          permissions &&
          permissions.result &&
          !checkPermissions(PermissionToCheck.AccessUnitDraft, actions.READ, permissions.result)
        ) {
          navigate('/accessDenied', {replace: true});
          return;
        }

        setUnitPermissions(permissions);
        setUnit(unit?.result);
        setApiMsg(unit?.message);
        const processes = await getUnitProcessNavigator(unitId);
        setProcessNavigatorData(processes?.result || []);

        const processList = await getMenuProcessesPublishedForUnit(unitId);
        setPublishedProcessesForUnit(processList);
        const documents = await getDocumentsRecentlyAdded(unitId);
        setRecentlyAddedDocument(documents.result || []);

        const itemsFlatPromise = await getProcessesPublishedForUnitFlatForManage(unitId);
        setItemsFlat(itemsFlatPromise);
        if (contextUnitId !== unitId) setContextUnitId(unitId);
        setIsLoading(false);
      }
    })();
    /* eslint-disable*/
  }, [isAzureLoading, unitId]);
  /* eslint-enable*/

  return {
    unitId,
    isLoading,
    processNavigatorData,
    apiMsg,
    publishedProcessesForUnit,
    unit,
    recentlyAddedDocument,
    unitPermissions,
    itemsFlat
  };
};
