import {
  ExternalLinkType,
  FunctionDto,
  ProjectSizeCategoryDto,
  SearchResultDto,
  IndustryDto,
  SpecialityDto,
  UserDto,
  BusinessLineDto,
  ProcessDtoUsedInLibraries,
  SearchType
} from '../services/generated';
import { DesignForExAreasType } from '../services/generated/models/DesignForExAreasType';
import { NativeToolType } from '../services/generated/models/NativeToolType';
import { YesNoNaType } from '../services/generated/models/YesNoNaType';
import {ProcessUsedInLibraries} from './ProcessUsedInLibraries';

export class SearchResult implements SearchResultDto {
  public title: string | null;
  public type: SearchType;
  public itemId: number;
  public unitId: number | null;
  public unitTitle: string | null;
  public unit: string | undefined;
  public url: string | null;
  public auditable: boolean;
  public documentOwner: string | null;
  public content: string | null;
  public library: string | null;
  public external: boolean;
  public function: FunctionDto | null;
  public language: string | null;
  public processes: ProcessDtoUsedInLibraries[] | null;
  public get languageCode() {
    return this.language;
  }
  public operatingUnit: string | null;
  public processOwner: UserDto | null;
  public secondaryProcessOwner: UserDto | null;
  public projectSizeCategories: ProjectSizeCategoryDto[] | null;
  public relativeUrl: string | null;
  public revision: string | null;
  public relevantToId: number | null;
  public relevantTo: string | null;
  public securityLevel: string | null;
  public industries: IndustryDto[] | null;
  public specialities: SpecialityDto[] | null;
  public documentId: string | null;
  public documentKind: string | null;
  public approved: string | null;
  public externalLinkType: ExternalLinkType;
  public isExternal: boolean | null;
  public documentInReview: boolean;
  public businessLines: BusinessLineDto[] | null;
  public typicalName: string | null;
  public nativeToolType: NativeToolType;
  public versionOfNativeTool: string | null;
  public storageOfNativeFile: string | null;
  public componentCatalogName: string | null;
  public designForExAreasType: DesignForExAreasType;
  public halogenFree: YesNoNaType;
  public redundancyType: YesNoNaType;
  public description: string | null;
  public cabinetType: string | null;
  public plinth: string | null;
  public height: string | null;
  public width: string | null;
  public depth: string | null;
  public ip: string | null;
  public cabinetAccess: string | null;
  public ioCount: number | null;
  public numberOfControllers: number | null;
  public equipmentOnDoor: YesNoNaType;
  public cableEntry: string | null;
  public powerSupply: string | null;
  public powerSupplyProducer: string | null;
  public cabinetCatalogNo: string | null;
  public manufacturedBy: string | null;
  public cabinetFrame: string | null;
  public controlSystem: string | null;
  public layout: YesNoNaType;
  public wiring: YesNoNaType;
  public fuseList: YesNoNaType;
  public bom: YesNoNaType;
  public previewUrl: string | null;
  public keyWords: string[] | null;
  public cabinetTypicalDocuments: string[] | null;
  public get id() {
    return this.itemId + this.type * 1000000000;
  }

  public get preparedUrl() {
    return !(this.type === SearchType.Process)
      ? this.url || ''
      : `/unit/${this.unitId}/process/${this.itemId}`;
  }

  public desingForExAreasString: string = '';
  public redundancyString: string = '';
  public nativeToolString: string = '';
  public halogenFreeString: string = '';
  public layoutString: string = '';
  public wiringString: string = '';
  public fuseListString: string = '';
  public bomString: string = '';
  public equipmentOnDoorString: string = '';

  public get typeString() {
    switch (this.type) {
      case SearchType.Process:
        return 'Process';
      case SearchType.Document:
        return 'Document';
      case SearchType.ExternalLink:
        return 'External Link';
      case SearchType.PDC:
        return 'PDC';
      case SearchType.CabinetTypical:
        return 'Cabinet Typical';
    }
    return '';
  }

  public get externalLinkTypeString() {
    switch (this.externalLinkType) {
      case ExternalLinkType.IMSProcess:
        return 'IMS Process';
      case ExternalLinkType.Site:
        return 'Site';
      case ExternalLinkType.SoftwareApplication:
        return 'Software Application';
      case ExternalLinkType.Examples:
        return 'Examples';
      case ExternalLinkType.ELearning:
        return 'e-Learning';
      default:
        return '';
    }
  }

  public functionString: string = '';

  constructor(searchResult: SearchResultDto, unit?: string) {
    this.title = searchResult.title;
    this.type = searchResult.type;
    this.itemId = searchResult.itemId;
    this.unitId = searchResult.unitId;
    this.unitTitle = searchResult.unitTitle;
    this.unit = unit;
    this.url = searchResult.url;
    this.auditable = searchResult.auditable;
    this.documentOwner = searchResult.documentOwner;
    this.content = searchResult.content;
    this.library = searchResult.library;
    this.external = searchResult.external;
    this.function = searchResult.function;
    this.language = searchResult.language;
    this.operatingUnit = searchResult.operatingUnit;
    this.processOwner = searchResult.processOwner;
    this.secondaryProcessOwner = searchResult.secondaryProcessOwner;
    this.projectSizeCategories = searchResult.projectSizeCategories;
    this.relativeUrl = searchResult.relativeUrl;
    this.securityLevel = searchResult.securityLevel;
    this.industries = searchResult.industries;
    this.specialities = searchResult.specialities;
    this.documentId = searchResult.documentId;
    this.documentKind = searchResult.documentKind;
    this.revision = searchResult.revision;
    this.relevantToId = searchResult.relevantToId;
    this.relevantTo = searchResult.relevantTo;
    this.approved = searchResult.approved;
    this.externalLinkType = searchResult.externalLinkType;
    this.isExternal = searchResult.external;
    this.documentInReview = searchResult.documentInReview;
    this.businessLines = searchResult.businessLines;
    this.keyWords = searchResult.keyWords;
    this.typicalName = searchResult.typicalName;
    this.designForExAreasType = searchResult.designForExAreasType;
    this.halogenFree = searchResult.halogenFree;
    this.description = searchResult.description;
    this.cabinetType = searchResult.cabinetType;
    this.plinth = searchResult.plinth;
    this.height = searchResult.height;
    this.width = searchResult.width;
    this.depth = searchResult.depth;
    this.ip = searchResult.ip;
    this.cabinetAccess = searchResult.cabinetAccess;
    this.ioCount = searchResult.ioCount;
    this.numberOfControllers = searchResult.numberOfControllers;
    this.cableEntry = searchResult.cableEntry;
    this.powerSupply = searchResult.powerSupply;
    this.powerSupplyProducer = searchResult.powerSupplyProducer;
    this.cabinetCatalogNo = searchResult.cabinetCatalogNo;
    this.manufacturedBy = searchResult.manufacturedBy;
    this.previewUrl = searchResult.previewUrl;
    this.bom = searchResult.bom;
    this.cabinetFrame = searchResult.cabinetFrame;
    this.controlSystem = searchResult.controlSystem;
    this.versionOfNativeTool = searchResult.versionOfNativeTool;
    this.storageOfNativeFile = searchResult.storageOfNativeFile
    this.componentCatalogName = searchResult.componentCatalogName;
    this.layout = searchResult.layout;
    this.wiring = searchResult.wiring;
    this.fuseList = searchResult.fuseList;
    this.redundancyType = searchResult.redundancyType;
    this.equipmentOnDoor = searchResult.equipmentOnDoor;
    this.nativeToolType = searchResult.nativeToolType;
    this.cabinetTypicalDocuments = searchResult.cabinetTypicalDocuments;
    this.processes =
      searchResult.processes?.map(process => new ProcessUsedInLibraries(process)) || null;
    Object.defineProperty(this, 'functionString', {
      get() {
        return searchResult.function?.value || '';
      }
    });

     Object.defineProperty(this, 'desingForExAreasString', {
          get() {
            switch (this.designForExAreasType) {
              case DesignForExAreasType.IS:
                return 'IS';
              case DesignForExAreasType.NONIS:
                return 'NON IS';
            }
            return '';
          }
        });
    
        Object.defineProperty(this, 'nativeToolString', {
          get() {
            switch (this.nativeToolType) {
              case NativeToolType.EB:
                return 'EB';
              case NativeToolType.Eplan:
                return 'Eplan';
              case NativeToolType.E3:
                return 'E3';
            }
            return '';
          }
        });
    
        Object.defineProperty(this, 'redundancyString', {
          get() {
            switch (this.redundancyType) {
              case YesNoNaType.Yes:
                return 'Yes';
              case YesNoNaType.No:
                return 'No';
              case YesNoNaType.NA:
                return 'N/A';
            }
            return '';
          }
        });
    
        Object.defineProperty(this, 'equipmentOnDoorString', {
          get() {
            switch (this.equipmentOnDoor) {
              case YesNoNaType.Yes:
                return 'Yes';
              case YesNoNaType.No:
                return 'No';
              case YesNoNaType.NA:
                return 'N/A';
            }
            return '';
          }
        });
    
        Object.defineProperty(this, 'halogenFreeString', {
          get() {
            switch (this.halogenFree) {
              case YesNoNaType.Yes:
                return 'Yes';
              case YesNoNaType.No:
                return 'No';
              case YesNoNaType.NA:
                return 'N/A';
            }
            return '';
          }
        });
    
        Object.defineProperty(this, 'bomString', {
          get() {
            switch (this.bom) {
              case YesNoNaType.Yes:
                return 'Yes';
              case YesNoNaType.No:
                return 'No';
              case YesNoNaType.NA:
                return 'N/A';
            }
            return '';
          }
        });
    
        Object.defineProperty(this, 'fuseListString', {
          get() {
            switch (this.fuseList) {
              case YesNoNaType.Yes:
                return 'Yes';
              case YesNoNaType.No:
                return 'No';
              case YesNoNaType.NA:
                return 'N/A';
            }
            return '';
          }
        });
    
        Object.defineProperty(this, 'wiringString', {
          get() {
            switch (this.wiring) {
              case YesNoNaType.Yes:
                return 'Yes';
              case YesNoNaType.No:
                return 'No';
              case YesNoNaType.NA:
                return 'N/A';
            }
            return '';
          }
        });
    
        Object.defineProperty(this, 'layoutString', {
          get() {
            switch (this.layout) {
              case YesNoNaType.Yes:
                return 'Yes';
              case YesNoNaType.No:
                return 'No';
              case YesNoNaType.NA:
                return 'N/A';
            }
            return '';
          }
        });
  }
}
