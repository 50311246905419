import {useMemo, useCallback, useEffect, useState, useContext} from 'react';
import {useNavigate} from 'react-router-dom';

import {useService} from '../../../hooks';
import {useUnit} from '../../../hooks/services/useUnit';
import {Unit} from '../../../models';
import {IPersonaProps} from '@fluentui/react';
import {checkPermissions} from '../../../helpers/permissionsHtml';
import {PermissionToCheck, actions} from '../../../enums';
import {useUser} from '../../../hooks/services/useUser';
import { GlobalSettingsContext } from '../../../providers/GlobalSettingsContext';
import { useAdmin } from '../../../hooks/services/useAdmin';

export const useUnitSettings = (unitId: number) => {
  const navigate = useNavigate();
  const {setGlobalSettings} = useContext(GlobalSettingsContext);
  const {getGlobalSettings} = useAdmin();

  const {updateSingleUnit} = useUnit();
  const {
    getUnit,
    getPortalOwners,
    getUserById,
    getUnitProcessOwner,
    updateProcessOwner,
    updatePortalOwners
  } = useService();
  const {getUserPermissionsPage} = useUser();

  const [monthsValue, setMonthsValue] = useState<string | undefined>('12');
  const [pdcLink, setPdcLink] = useState<string | null>(null);
  const [pdcOperatingUnit, setPdcOperatingUnit] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isFormInvalid, setIsFormInvalid] = useState<boolean>(false);
  const [isMonthsInvalid, setIsMonthsInvalid] = useState<boolean>(false);
  const [unitDetails, setUnitDetails] = useState<Unit | null>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [portalOwners, setPortalOwners] = useState<IPersonaProps[]>();
  const [portalOwnersSelect, setPortalOwnersSelect] = useState<IPersonaProps[] | undefined>();
  const [isPortalOwnersInvalid, setIsPortalOwnersInvalid] = useState<boolean>(false);
  const [processOwners, setProcessOwners] = useState<IPersonaProps[]>();
  const [processOwnersSelect, setProcessOwnersSelect] = useState<IPersonaProps[] | undefined>();
  const [isProcessOwnersInvalid, setIsProcessOwnersInvalid] = useState<boolean>(false);
  const [turnOffNotifications, setTurnOffNotifications] = useState<boolean>(false)
  const [unitTitle, setUnitTitle] = useState<string>();

  const getUser = useCallback(
    async (userAzureId: string): Promise<IPersonaProps | undefined> => {
      if (userAzureId) {
        const user = await getUserById(userAzureId);
        const userToReturn = user
          ? {
              id: user.id,
              text: user.displayName,
              secondaryText: user.mail,
              key: user.id
            }
          : undefined;
        return userToReturn;
      }
    },
    [getUserById]
  );

  const processPortalOwners = useCallback(async () => {
    const portalOwnersList = await getPortalOwners(unitId);
    if (portalOwnersList?.result) {
      let portalOwners: IPersonaProps[] = [];
      await Promise.all(
        portalOwnersList.result.map(async element => {
          const portalOwner = await getUser(element.azureId);
          if (portalOwner) {
            portalOwners.push(portalOwner);
          }
        })
      );
      setPortalOwners(portalOwners);
      setPortalOwnersSelect(portalOwners);
    } else {
      setPortalOwners(undefined);
    }
  }, [getUser, getPortalOwners, unitId]);

  const changeTurnOffNotifications = useCallback(() => {
     setTurnOffNotifications(!turnOffNotifications);
  
  },[turnOffNotifications])

  const prepareUnitTitle = (unit: Unit): string => {
    if (unit.title) {
      return unit.parentTitle && unit.unitTypeId === 2
        ? `${unit.parentTitle} ${unit.title}`
        : unit.title;
    }
    return '';
  };

  const refreshData = useCallback(async () => {
    await processPortalOwners();
    const processOwner = await getUnitProcessOwner(unitId);
    const processOwnerAzureId = processOwner.result?.azureId;
    if (processOwnerAzureId) {
      const processOwner = await getUser(processOwnerAzureId);
      const processOwnerResult = processOwner ? [processOwner] : [];
      setProcessOwners(processOwnerResult);
      setProcessOwnersSelect(processOwnerResult);
    } else {
      setProcessOwners([]);
      setProcessOwnersSelect([]);
    }
    const response = await getUnit(unitId);
    const unitDetailsData = response.result;
    if (!unitDetailsData) return;
    setUnitTitle(prepareUnitTitle(unitDetailsData))
    setUnitDetails(unitDetailsData);
    setMonthsValue(unitDetailsData.processValidityInMonth.toString());
    setPdcLink(unitDetailsData.pdcLink);
    setPdcOperatingUnit(unitDetailsData.pdcOperatingUnit);
    setTurnOffNotifications(unitDetailsData.turnOffNotifications)
  }, [getUnit, getUnitProcessOwner, getUser, processPortalOwners, unitId]);

  const cancelForm = useCallback(() => {
    const fullUrl = `/unit/${unitId}/manage`;
    navigate(fullUrl, {replace: true});
  }, [navigate, unitId]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await refreshData();
      setIsLoading(false);
    })();
  }, [refreshData, unitId]);

  const onSelectPortalOwners = useCallback((value: IPersonaProps[] | undefined) => {
    setPortalOwnersSelect(value);
  }, []);

  const onSelectProcessOwner = useCallback((value: IPersonaProps[] | undefined) => {
    setProcessOwnersSelect(value);
  }, []);

  const onChangeTurnOffNotification = useCallback(async (defaultTurnOffNotification: boolean) => {
    if(defaultTurnOffNotification === turnOffNotifications)
    {
      return;
    }
    
      const settings = (await getGlobalSettings()).result;
      if(settings)
      {
        setGlobalSettings(settings,turnOffNotifications,[], unitId.toString());
      }     
    }
  ,[getGlobalSettings,setGlobalSettings,turnOffNotifications, unitId])

  const saveForm = useCallback(async () => {
    const monthsInvalid = !monthsValue || Number(monthsValue) < 1;
    setIsMonthsInvalid(monthsInvalid);
    const portalOwnersInvalid = !portalOwnersSelect?.length;
    setIsPortalOwnersInvalid(portalOwnersInvalid);
    const processOwnersInvalid = !processOwnersSelect?.length;
    setIsProcessOwnersInvalid(processOwnersInvalid);
    const isInvalid = monthsInvalid || portalOwnersInvalid || processOwnersInvalid;
    setIsFormInvalid(isInvalid);
    if (!isInvalid && unitDetails) {
      const defaultTurnOffNotification = unitDetails?.turnOffNotifications
      setIsSaving(true);
      const data = {
        ...unitDetails,
        processValidityInMonth: Number(monthsValue)
      };
      data.pdcLink = pdcLink;
      data.pdcOperatingUnit = pdcOperatingUnit;
      data.turnOffNotifications = turnOffNotifications;
      await updateSingleUnit(unitId, data);
      if (
        processOwners?.length !== portalOwnersSelect.length ||
        processOwnersSelect[0].id !== processOwners[0].id
      ) {
        await updateProcessOwner(unitId, processOwnersSelect[0]?.id || '');
      }
      if (
        portalOwners?.length !== portalOwnersSelect.length ||
        portalOwnersSelect.some((elem, index) => elem.id !== portalOwners[index].id)
      ) {
        await updatePortalOwners(
          unitId,
          portalOwnersSelect.map(item => item.id || '').filter(item => item)
        );
      }
      const response = await getUserPermissionsPage(unitId, undefined, true);
      if (!checkPermissions(PermissionToCheck.UnitManageButton, actions.UPDATE, response.result)) {
        navigate(`/unit/${unitId}`, {replace: true});
        return;
      }
      await refreshData();
      await onChangeTurnOffNotification(defaultTurnOffNotification)
      setIsSaving(false);
    
    }
  }, [
    monthsValue,
    portalOwnersSelect,
    processOwnersSelect,
    unitDetails,
    pdcLink,
    pdcOperatingUnit,
    turnOffNotifications,
    portalOwners,
    processOwners,
    onChangeTurnOffNotification,
    updateSingleUnit,
    unitId,
    getUserPermissionsPage,
    refreshData,
    updatePortalOwners,
    updateProcessOwner,
    navigate
  ]);

  return useMemo(
    () => ({
      isFormInvalid,
      unitTitle,
      isSaving,
      isLoading,
      unitDetails,
      monthsValue,
      turnOffNotifications,
      setMonthsValue,
      pdcLink,
      setPdcLink,
      pdcOperatingUnit,
      setPdcOperatingUnit,
      cancelForm,
      changeTurnOffNotifications,
      saveForm,
      isMonthsInvalid,
      portalOwnersSelect,
      onSelectPortalOwners,
      processOwnersSelect,
      onSelectProcessOwner,
      isPortalOwnersInvalid,
      isProcessOwnersInvalid
    }),
    [
      isFormInvalid,
      unitTitle,
      isSaving,
      isLoading,
      unitDetails,
      monthsValue,
      turnOffNotifications,
      setMonthsValue,
      pdcLink,
      setPdcLink,
      pdcOperatingUnit,
      setPdcOperatingUnit,
      cancelForm,
      changeTurnOffNotifications,
      saveForm,
      isMonthsInvalid,
      portalOwnersSelect,
      onSelectPortalOwners,
      processOwnersSelect,
      onSelectProcessOwner,
      isPortalOwnersInvalid,
      isProcessOwnersInvalid
    ]
  );
};
