import {Toggle, TooltipHost} from '@fluentui/react';
import {FC} from 'react';

interface IMirrorCopyRow {
  model: {
    nextUpdate: string;
    isMirrorCopy: boolean;
    mirrorCopyEnabled: boolean
  };
  onChange: (model: any, checked: boolean) => void;
}

export const MirrorCopyRow: FC<IMirrorCopyRow> = ({model, onChange}) => {

  return (
    <TooltipHost
      content={
        model.mirrorCopyEnabled ? '' : 'Mirror copy property is disabled for Indepenedent process'
      }>
      <Toggle
        disabled={!model.mirrorCopyEnabled}
        checked={model.isMirrorCopy}
        onChange={(ev, checked) => onChange(model, !!checked)}
      />
    </TooltipHost>
  );
};
