import {Links, UserDto} from '../services/generated';
import { CabinetTypicalDocumentDto } from '../services/generated/models/CabinetTypicalDocumentDto';


export class CabinetTypicalDocument implements CabinetTypicalDocumentDto {
    public id: number;
    public readonly link: Array<Links> | null;
    public created: string | null;
    public createdBy: UserDto | null;
    public deleted: boolean;
    public modified: string | null;
    public modifiedBy: UserDto | null;
    public version: number;
    public name: string | null;
    public isLayout: boolean;
    public url: string;
    public pdcId: number;
    public cabinetTypicalName: string | null


  constructor(cabinetTypical: CabinetTypicalDocumentDto) {
    this.id = cabinetTypical.id;
    this.link = cabinetTypical.link;
    this.created = cabinetTypical.created;
    this.createdBy = cabinetTypical.createdBy;
    this.deleted = cabinetTypical.deleted;
    this.modified = cabinetTypical.modified;
    this.modifiedBy = cabinetTypical.modifiedBy;
    this.version = cabinetTypical.version;
    this.name = cabinetTypical.name;
    this.isLayout = cabinetTypical.isLayout;
    this.url = cabinetTypical.url;
    this.pdcId = cabinetTypical.pdcId;
    this.cabinetTypicalName = cabinetTypical.cabinetTypicalName;

    }
  }

