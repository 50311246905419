import {useMemo} from 'react';

import {useDocument} from './services/useDocument';
import {useExternalLinks} from './services/useExternalLinks';
import {useMasterProcess} from './services/useMasterProcess';
import {useProcess} from './services/useProcess';
import {useSearch} from './services/useSearch';
import {useUnit} from './services/useUnit';
import {useUser} from './services/useUser';
import {useGroup} from './services/useGroup';

export const useService = () => {
  const {
    acquireToken,
    getCurrentUser,
    getCurrentUserAdminDetails,
    getUserPermissionsPage,
    getUserById,
    getAllUsersList,
    getFilteredUsersList,
    logout
  } = useUser();

  const {createMasterProcess, getMasterProcess, createIndependentProcessInUnit} = useMasterProcess();

  const {
    getUnits,
    getUnit,
    getUnitForUnitPage,
    getUnitManage,
    getUnitProcessNavigator,
    getUnitManageProcessNavigator,
    updateUnitProcessNavigators,
    updateSingleUnit,
    deleteUnit,
    getProcessesPublishedForUnit,
    getProcessesToManage,
    addProcessToUnit,
    getDocumentsRecentlyAdded,
    getPortalOwners,
    updatePortalOwners,
    updateNavigatorControl,
    updateProcessToManage,
    getMenuProcessesPublishedForUnit,
    getProcessesPublishedForUnitFlat,
    getProcessesPublishedForUnitFlatForManage,
    getUnitProcessOwner,
    updateProcessOwner
  } = useUnit();

  const {
    getProcesses,
    createProcess,
    deleteProcess,
    getProcess,
    getProcessChanges,
    getProcessVersionsDraft,
    updateProcessDraft,
    publishProcess,
    getBreadcrumbs,
    getSubscription,
    putSubscription,
    deleteSubscription
  } = useProcess();

  const {getDocuments} = useDocument();

  const {getExternalLinks} = useExternalLinks();

  const {getSearchResults} = useSearch();

  const {getGroupById, getFilteredGroupsList} = useGroup();

  return useMemo(
    () => ({
      acquireToken,
      getCurrentUser,
      getCurrentUserAdminDetails,
      getUserPermissionsPage,
      getAllUsersList,
      getFilteredUsersList,
      getFilteredGroupsList,
      getUserById,
      getGroupById,
      updateSingleUnit,
      addProcessToUnit,
      getMasterProcess,
      createMasterProcess,
      createIndependentProcessInUnit,
      getProcesses,
      createProcess,
      deleteProcess,
      getProcess,
      getProcessChanges,
      getProcessVersionsDraft,
      updateProcessDraft,
      publishProcess,
      getBreadcrumbs,
      getSubscription,
      putSubscription,
      deleteSubscription,
      getUnits,
      getUnit,
      getUnitForUnitPage,
      getUnitManage,
      getUnitProcessNavigator,
      getUnitManageProcessNavigator,
      updateUnitProcessNavigators,
      deleteUnit,
      getProcessesPublishedForUnit,
      getProcessesToManage,
      getDocumentsRecentlyAdded,
      getPortalOwners,
      updatePortalOwners,
      updateNavigatorControl,
      updateProcessToManage,
      getDocuments,
      getExternalLinks,
      getSearchResults,
      getMenuProcessesPublishedForUnit,
      getProcessesPublishedForUnitFlat,
      getProcessesPublishedForUnitFlatForManage,
      getUnitProcessOwner,
      updateProcessOwner,
      logout
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};
